.BookDetailInapp {
    font-family: Arial, sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: -0.02px;
    text-align: left;
    max-width: 740px;
    color: #292929;
    margin: 20px auto 200px auto;
    user-select: none;
}
.DownloadPDF {
    text-align: center;
    width: 95%;
    margin: 0 auto;
}
.DownloadPDF_SingleBtn {
    margin-top: 20px;
}

.BookDetail_Paywall_Discount {
    margin: 0px auto;
    padding: 20px;
    border-radius: 10px;
    border-color: #32A8EE;
    margin-bottom: 20px;
}

.BookDetail_Paywall_Discount img {
    width: 100px;
    height: auto;
    margin: 20px auto;
    display: block;
}

.BookDetail_ContactUsBox {
    width: 100%;
    height: 250px;
    margin: 20px auto;
    padding: 10px;
}

.BookDetail_ContactUsBoxH2 {
    margin: 50px 0 0 0;
}

.DownloadPDF p {
    margin: 20px auto;
}
.DownloadPDF img {
    width: 200px;
    height: auto;
}

.BookDetailInapp * {
    font-family: Arial, Helvetica Neue, sans-serif;
}

.dark {
    color: white;
    background: black;
}

.dark .sidebar {
    background-color: black;
}

.dark * {
    color: white !important;
}

.dark .BookDetailInapp .bottom {
    background-color: black;
}

.BookDetailInapp .table-of-contents {
    width: 454px;
    max-width: 90%;
}

.BookDetailInapp .table-of-contents h2 {
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #123048;
    margin: 0;
}

.BookDetailInapp .table-of-contents .sidebar-wrapper {
    margin: 75px 90px 131px 83px;
    padding-right: 39px;
    border-right: 1px solid #ededed;
}

.BookDetailInapp .options-sidebar {
    width: 454px;
    max-width: 80%;
}
.BookDetailInapp .options-sidebar .sidebar-wrapper {
    margin: 75px 90px 0 33px;
    border-right: 1px solid #ededed;
}

.BookDetailInapp .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #3A4649;
    opacity: 0.5;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
}

.BookDetailInapp .table-of-contents .closebtn {
    width: 46px;
    height: 46px;
    background-image: url(/images/list.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    right: 10px;
    top: 161px;
    border: none;
    background-color: white;
}


.BookDetailInapp .sidebar-link {
    margin: 35px 0 0 0;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: left;
    color: #292929;
    position: relative;
    vertical-align: middle;
}

.BookDetailInapp .timeline {
    position: relative;
}

/** quiz **/
.choice,
.fill-choice {
    border: 2px solid #dddddd;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}
.wrong-choice {
    border: 2px solid red;
}
.quiz-container {
    margin: 10px 0 60px 0;
}

.quiz-container h4 {
    text-transform: uppercase;
    color: #a024ea;
}
.right-choice {
    border: 2px solid green;
}
.flash-card {
    width: 100%;
    height: 200px;
    position: relative;
    max-width: 350px;
}
.side {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border: 1px solid #ccc;
}
.flip-card {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.8s;
    position: relative;
}

.flip-quiz, .multiple-quiz, .flash-quiz {
    display: none;
}

.explanation {
    margin-top: 10px;
}

.flip-front {
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    background: white;
}
.flip-back {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    text-align: center;
    background: white;
    transform: rotateY(180deg);
}
.flip-card img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 50%;
}
.flip-bottom {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 16px;
}
.hide {
    display: none;
}
.fill-in-blank {
    text-align: center;
    border-bottom: 1px solid #a024ea;
    display: inline-block;
    width: 150px;
}
@keyframes correct-answer {
    0% {
        transform: scale(1);
        background-color: #dff0d8;
    }
    50% {
        transform: scale(1.1);
        background-color: #c8e5bc;
    }
    100% {
        transform: scale(1);
        background-color: #dff0d8;
    }
}
.correct {
    border-bottom-color: #3c763d;
    animation: correct-answer 0.5s ease-out;
}

/** quiz **/
.BookDetailInapp .timeline::after {
    content: '';
    position: absolute;
    width: 1px;
    background-color: #ededed;
    top: 0;
    bottom: 0;
    left: -36px;
    margin-left: -3px;
}

.BookDetailInapp .chapter-notcomplete::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    left: -48px;
    background-color: #ededed;
    border: 4px solid #ededed;
    top: 0;
    border-radius: 50%;
    z-index: 1;
}

.BookDetailInapp .chapter-complete {
    color: #888;
}

.BookDetailInapp .chapter-complete::after {
    content: '';
    position: absolute;
    z-index: 1;
    background-color: white;
    background-image:url('/images/chapter-complete.svg');
    background-position: center center;
    background-size: contain;
    width: 28px;
    height: 28px;
    border: none;
    left: -54px;
    top: 0;
}

.dark .BookDetailInapp .chapter-complete::after {
    background-color: black;
}

.BookDetailInapp .paywall {
    text-align: center;
    padding: 30px;
    max-width: 600px;
    font-size: 16px;
    margin: 10px auto 0;
    background-color: #f6f9ff;
    line-height: 1.3;
    margin-top: 0px;
}

.dark .BookDetailInapp .paywall {
    background-color: black;
    color: white;
}

.dark .BookDetailInapp_UpgradeNow {
    background-color: black;
    color: white;
}

.BookDetailInapp .paywall a {
    margin-top: 30px;
    margin-bottom: 40px;
    display: inline-block;
}

.BookDetailInapp .paywall p {
    text-align: center;
}


.BookDetailInapp .paywall h3 {
    border: none;
    padding-bottom: 30px;
    margin-bottom: 20px;
    text-align: center;

}
.BookDetailInapp .paywall h2 {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 30px;
}
.BookDetailInapp .lastFree {
    text-align: left;
    padding: 20px 20px 20px 20px;
    width: 100%;
    margin: 0 auto 40px auto;
    background-color: #F6F9FF;
    line-height: 1.3;
}

.BookDetailInapp .lastFree p {
    font-size: 16px;
    margin: 20px 0;
}


.BookDetailInapp .lastFree a {
    margin: 20px 0 30px 0;
}

.BookDetailInapp .lastFree h2 {
    margin: 0;
}

.BookDetailInapp a.StandardButton-4 {
    color: white;
}

.BookDetailInapp .book-detail-header {
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    background-color: #f1f1f1;
}

.BookDetailInapp h3 {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 49px;
}
/* The progress container (grey background) */
.BookDetailInapp .progress-container {
    width: 100%;
    height: 8px;
    background: #ccc;
}

/* The progress bar (scroll indicator) */
.BookDetailInapp .progress-bar {
    height: 8px;
    background: #4caf50;
    width: 0%;
}

.BookDetailInapp .body {
    padding-bottom: 50px;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 35px;
}


.BookDetailInapp .bodyNotLoggedIn {
    border: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.BookDetailInapp .bottom {
    height: 77px;
    position: fixed;
    bottom: 0;
    box-shadow: 0 -8px 14px rgba(20,28,49,.05);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #123048;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
}

.BookDetailInapp .bottom .audio {
    max-width: 33%;
}
.BookDetailInapp .bottom div {
    padding: 0;
    margin: 0;
    text-align: center;
}

.BookDetailInapp h1 {
    font-size: 34px;
    margin-bottom: 40px;
}


.BookDetailInapp h2 {
    margin-bottom: 20px;
}

.BookDetailInapp h3 {
    font-size: 34px;
    margin-bottom: 40px;
}

.BookDetailInapp h4 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

.BookDetailInapp p {
    margin-bottom: 20px;
}

.BookDetailInapp li p {
    display: inline;
}

.BookDetailInapp h1, .BookDetailInapp h2, .BookDetailInapp h3, .BookDetailInapp h4  {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #292929;
}
.BookDetailInapp ul,.BookDetailInapp ol  {
    list-style: inside;
}

.BookDetailInapp p, .BookDetailInapp li {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: -0.02px;
    text-align: left;
    color: #292929;
}

.BookDetailInapp li {
    margin-bottom: 10px;
}

.BookDetailInapp ul, .BookDetailInapp ol {
    font-size: 20px;
    margin-bottom: 20px;
}

.BookDetailInapp a {
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.08px;
    text-align: right;
    color: black;
}

.BookDetailInapp .progress-bar-wrapper {
    width: 100%;
    height: 5px;
    position: fixed;
    background-color: rgba(111, 119, 125, 0.6);
    bottom: 77px;
    left: 0;
}


.BookDetailInapp .progress-bar {
    background-color: #32a8ee;
    height: 5px;
}

.BookDetailInapp .incrdec {
    width: 50%;
    font-size: 17px;
    display: inline-block;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.08px;
    text-align: right;
    color: #4151e6;
}

.BookDetailInapp .prev {
    text-align: left;
    float: left;
    font-size: 17px;
}

.BookDetailInapp .next {
    text-align: right;
    float: right;
    font-size: 17px;
}

.BookDetailInapp div.video {
    background-image: url(/images/video.svg);
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
    padding-left: 26px;
    text-align: left;
    color: #5a5a5a;
    max-width: 30%;
    margin-left: 3%;
}

.BookDetailInapp div.video a {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    color: #5a5a5a;
    letter-spacing: -0.08px;
    margin: 0 auto;
    text-align: center;
}
.BookDetailInapp div.title {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: center;
    color: #123048;
    display: inline-block;
    width: 33%;
}
.BookDetailInapp .list {
    width: 46px;
    height: 46px;
    background-image: url(/images/list.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 20px;
    top: 100px;
    border: none;
    background-color: white;
}

.dark .BookDetailInapp .list {
    background-color: transparent;
    background-image: url(/images/list-white.svg);
    width: 25px;
    height: 25px;
    left: 23px;
}

.BookDetailInapp .options {
    width: 46px;
    height: 46px;
    background-image: url(/images/options.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 20px;
    top: 201px;
    border: none;
    background-color: white;
}

.dark .BookDetailInapp .options {
    background-color: transparent;
    background-image: url(/images/menu-white.svg);
    width: 25px;
    height: 25px;
    left: 25px;
}

.BookDetailInapp .options-sidebar .closebtn {
    width: 46px;
    height: 46px;
    background-image: url(/images/options.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    right: 10px;
    top: 161px;
    border: none;
    background-color: white;
}

.dark .BookDetailInapp .table-of-contents .closebtn {
    background-image: url(/images/list-white.svg);
    background-color: black;
    width: 30px;
    height: 30px;
}

.dark .BookDetailInapp .options-sidebar .closebtn {
    background-image: url(/images/menu-white.svg);
    background-color: black;
    width: 30px;
    height: 30px;
}

.BookDetailInapp .close-book {
    background-image: url(/images/close-circle-outline-1.svg);
    background-position: left center;
    background-size: 30px 30px;
    font-size: 17px;
    padding-left: 40px;
    font-weight: 500;
    font-stretch: normal;
    display: block;
    font-style: normal;
    line-height: 1.29;
    background-repeat: no-repeat;
    letter-spacing: -0.43px;
    text-align: left;
    color: #ff4b68;
    cursor: pointer;
}
.BookDetailInapp .send-to-kindle {
    background-image: url(/images/file-send-outline-1.svg);
    background-position: left center;
    padding-left: 40px;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    margin-top: 49px;
    display: block;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    cursor: pointer;
    line-height: 1.29;
    letter-spacing: -0.43px;
    text-align: left;
    color: #32a8ee;
}

.BookDetailInapp .download {
    background-image: url(../../../assets/images/downloadpdf.svg);
    background-position: 4px center;
    padding-left: 40px;
    background-repeat: no-repeat;
    background-size: 20px auto;
    margin-top: 49px;
    display: block;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    cursor: pointer;
    line-height: 1.29;
    letter-spacing: -0.43px;
    text-align: left;
    color: #32a8ee;
}

.BookDetail_UpgradeNow {
    margin-bottom: 20px;
}

._book_trial_alert {
    background: #EAF7FF;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 40px;
}

.dark ._book_trial_alert {
    background-color: black;
    color: white;
    border: 1px solid #32A8EE;
    padding-bottom: 10px;
}


._book_trial_alert ._alert_btn {
    display: inline-block;
    width: 190px;
    height: 50px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #1A3849;
    background-color: transparent;
    border: 1px solid #32A8EE;
    border-radius: 8px;
    line-height: 50px;
}

._book_trial_alert ._alert_btn:hover {
    color: #FFFFFF;
    background-color: #32A8EE;
}



.BookDetailInapp .add-remove-library {
    background-image: url(/images/bookmark-outline-1.svg);
    background-position: left center;
    padding-left: 40px;
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: 30px 30px;
    margin-bottom: 49px;
    margin-top: 30px;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.43px;
    text-align: left;
    color: #32a8ee;
}

.BookDetailInapp .font {
    width: 46px;
    height: 46px;
    background-image: url(/images/text.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 20px;
    top: 150px;
    border: none;
    background-color: white;
}

.dark .BookDetailInapp .font {
    background-color: transparent;
    background-image: url(/images/text-size-white.svg);
    width: 30px;
    height: 30px;
}


.BookDetailInapp .darkmode {
    width: 30px;
    height: 30px;
    background-image: url(/images/dark-mode.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 30px;
    top: 263px;
    border: none;
    background-color: white;
}

.dark .BookDetailInapp .darkmode {
    background-color: transparent;
    background-image: url(/images/dark-mode-white.svg);
    width: 25px;
    height: 25px;
    left: 25px;
    top: 260px;
}

.BookDetailInapp .text-1 p, .BookDetailInapp .text-1 ul, .BookDetailInapp .text-1 ol, .BookDetailInapp .text-1 li {
    font-size: 17px;
}

.BookDetailInapp .text-2 p, .BookDetailInapp .text-2 ul, .BookDetailInapp .text-2 ol, .BookDetailInapp .text-2 li  {
    font-size: 23px;
}
.BookDetailInapp .text-3 p, .BookDetailInapp .text-3 ul, .BookDetailInapp .text-3 ol, .BookDetailInapp .text-3 li  {
    font-size: 29px;
}

@media screen and (max-width: 840px) {

    .BookDetailInapp ul, .BookDetailInapp ol {
        font-size: 18px;
    }


    .BookDetailInapp {
        padding: 30px;
        margin: 0;
        width: 100%;
        margin-bottom: 200px;
    }

    .BookDetailInapp .detail-menu {
        margin: 0;
        margin-left: -8px;
        margin-top: -11px;
    }

    .dark .BookDetailInapp .detail-menu {
        margin: 0;
        margin-top: 0px;
    }

    .BookDetailInapp .bottom .title {
        display: none;
    }

    .BookDetailInapp .bottom .audio {
        max-width: 67%;
    }

    .BookDetailInapp .bottom .video {
        max-width: 30%;
    }
    .BookDetailInapp .bottom {
        padding: 20px;
    }
    .BookDetailInapp .list {
        position: static;
        margin-bottom: 30px;
        vertical-align: top;
    }
    .BookDetailInapp .font {
        margin-bottom: 30px;
        position: static;
        vertical-align: top;
    }
    .BookDetailInapp .options {
        position: static;
        margin-bottom: 30px;
        vertical-align: top;
    }

    .BookDetailInapp .darkmode {
        position: static;
        margin: 0;
        padding: 0;
        vertical-align: top;
        margin-left: 7px;
        margin-top: 7.5px;
    }

    .dark .BookDetailInapp .list {
        position: static;
        margin-bottom: 30px;
        margin-right: 20px;
    }
    .dark .BookDetailInapp .font {
        margin-bottom: 30px;
        position: static;
        margin-right: 15px;
    }
    .dark .BookDetailInapp .options {
        position: static;
        margin-bottom: 30px;
        margin-right: 15px;
    }
    .dark .BookDetailInapp .darkmode {
        margin-right: 20px;
        margin-left: 0;
        margin-top: 0px;
        width: 25px;
        height: 25px;
    }

    .detail-menu {
        display: inline-block;
        vertical-align: top;
    }

    ._book_trial_alert img {
        margin-bottom: 20px;
    }
}

