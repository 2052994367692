.FullBookSearch .BookList {
    justify-content: center;
    margin: 0 auto;
}
.FullBookSearchInput {
    margin-top: 8px;
    margin-right: 16px;
    font-size: 17px;
    width: 300px;
}
.BookSearch {
    text-align: center;
    margin: 0 auto;
}
