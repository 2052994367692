

/*footer sumizeit section*/
._footer_sumizeit_wrapper {
    margin: 100px 0 40px;
    padding-top: 50px;
    overflow: hidden;
}

._footer_sumizeit_wrap {
    overflow: hidden;
}

._footer_sumizeit_logo {
    width: 164px;
    margin-bottom: 18px;
}

.Footer_AppStore {
    width: 150px;
    height: auto;
}

._footer_sumizeit_logo_cont {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 1.6;
    color: rgba(26, 56, 73, 0.8);
    max-width: 68%;
    margin-bottom: 32px;
}

._footer_sumizeit_menu_txt {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 16px;
    line-height: 1.2;
    color: #1A3849;
    margin-bottom: 14px;
}

._footer_sumizeit_socail_ics_ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0 20px;
}

._footer_sumizeit_socail_ic svg {
    transition: .2s all ease-in-out;
    transform: translateY(0) scale(1);
}

._footer_sumizeit_socail_ic:hover svg {
    transform: translateY(-6px) scale(1.099);
}

._footer_sumizeit_menu_li {
    margin-bottom: 18px;
}

._footer_sumizeit_menu_li:last-child {
    margin-bottom: 0;
}

._footer_sumizeit_menu_itm {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: rgba(26, 56, 73, 0.6) !important;
    display: flex;
    transition: .2s all ease;
}

._footer_sumizeit_menu_itm:hover {
    color: rgba(26, 56, 73, 1) !important;
}


._footer_sumizeit_subscribe_form_inpt {
    border: 1px solid #000000;
    border-radius: 8px;
    width: 100%;
    height: 48px;
    color: #000;
    padding: 0 10px 0 15px;
    margin-bottom: 16px;
}

._footer_sumizeit_subscribe_form_inpt::placeholder {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: rgba(26, 56, 73, 0.6);
}

._footer_sumizeit_subscribe_form_inpt_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s all ease-in-out;
    width: 112px;
    height: 38px;
    background: #32A8EE;
    border-radius: 8px;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
    text-align: center;
    color: #FFFFFF !important;
    border: 0;
}

._footer_sumizeit_subscribe_form_inpt_btn:hover {
    background: #159dec;
}

._footer_sumizeit_copyr8 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}

._footer_sumizeit_copyr8_txt {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    text-align: center;
    color: rgba(26, 56, 73, 0.6);
}


@media only screen and (max-width: 1199px) {
    ._footer_sumizeit_logo_cont {
        max-width: 92%;
    }
}

/* Ipad pro */
@media only screen and (max-width: 991px) {
    ._footer_sumizeit_wrapper {
        margin: 60px 0 30px;
    }

    ._footer_sumizeit_logo_cont {
        max-width: 80%;
        margin-bottom: 25px;
    }

    ._footer_sumizeit_logo_cont_wrap {
        margin-bottom: 37px;
    }
}

/* mobile device */
@media only screen and (max-width: 575px) {
    ._footer_sumizeit_wrapper {
        margin: 50px 0 28px;
    }

    ._footer_sumizeit_menu {
        margin-bottom: 20px;
    }

    ._footer_sumizeit_copyr8 {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 420px) {
    ._footer_sumizeit_logo {
        width: 140px;
        margin-bottom: 14px;
    }
}