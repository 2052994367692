/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
    display: block;
}


body, html {
    font-size: 16px;
    line-height: 1.5;
}

body, button, input, textarea {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}


a {
    color: #32A8EE;
}


html, body {
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 15px;
    line-height: 1.5;
}

h1 {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 34px;
    margin: 20px 0;
    font-weight: 700;
    line-height: 1.5;
}

h2 {
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 26px;
    line-height: 1.5;
    font-weight: bold;
}

h3 {
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.5;
}

h4 {
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
}


h1, h4 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: bold;
    line-height: 1.5;
}

p, div {
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
}

strong, b {
    font-weight: 700;
}

.button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    user-select: none;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.Main {
    max-width: 1108px;
    margin: 10px auto;
    padding: 0 30px;
}

.MainSticky {
    margin-top: 20px;
}

.clear {
    clear: both;
}

.Main h1 {
    font-size: 32px;
}

.Main h2 {
    font-size: 24px;
}

.Main h3 {
    font-size: 25px;
}

a {
    text-decoration: none;
}


.button-23 {
    background-color: #FFFFFF;
    border: 1px solid #222222;
    border-radius: 8px;
    box-sizing: border-box;
    color: #222222;
    cursor: pointer;
    display: inline-block;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin: 0;
    outline: none;
    padding: 10px 10px;
    position: relative;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    transition: box-shadow .2s, -ms-transform .1s, -webkit-transform .1s, transform .1s;
    user-select: none;
    -webkit-user-select: none;
    width: auto;
}

.button-23:focus-visible {
    box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
    transition: box-shadow .2s;
}

.button-23:active {
    background-color: #F7F7F7;
    border-color: #000000;
    transform: scale(.96);
}

.button-23:disabled {
    border-color: #DDDDDD;
    color: #DDDDDD;
    cursor: not-allowed;
    opacity: 1;

}

.MainTitle {
    padding: 14px 0px;
    margin: 40px auto 20px auto;
    font-weight: bold;
    font-size: 22px;
    width: 100%;
}

.StandardButton-4 {
    border-radius: 5px;
    font-weight: 700;
    padding: 15px;
    font-size: 16px;
    line-height: 1.133;
    -webkit-font-smoothing: antialiased;
    transition: 150ms ease;
    transition-property: background-color, border-color, box-shadow, color;
    text-align: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: none;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,0.15);
    background-color: #32A8EE;
    color: #ffffff;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
}

.fine-print {
    font-size: 12px;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 150px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.StandardButton-4:hover {
    color: #ffe506;
}

.StandardButton-1 {
    background-color: white;
    border: 1px solid #32A8EE;
    color: #32A8EE;
    font-weight: bold;
    border-radius: 40px;
    padding: 10px 20px;
    outline: none;
}

button {
    outline: none;
}

.notice {
    text-align: left;
    padding: 20px 20px 20px 20px;
    font-family: "Helvetica Neue", Arial, sans-serif;
    width: 100%;
    margin: 10px auto 0 auto;
    background-color: #F6F9FF;
    line-height: 1.3;
}

.notice  p {
    font-size: 16px;
}

.notice h2 {
    margin: 0;
}

.StandardInput-1 {
    border-radius: 40px;
    padding: 10px;
    outline: none;
    text-align: left;
    border: 1px solid #32A8EE;
    color: #32A8EE;
}

.AlertSuccess {
    font-size: 16px;
    padding: 20px;
    line-height: 24px;
    border: 4px solid #ddd;
    margin: 50px auto;
}

.Alert-info {
    font-size: 16px;
    padding: 20px;
    line-height: 24px;
    border: 4px solid #ddd;
    margin: 50px auto;
}



._footer_social_icons {
    margin: 10px 0 10px 0;
}

._footer_social_icon {
    width: 30px;
    height: auto;
    margin-right: 10px;
}

.Alert-error {
    font-size: 16px;
    padding: 20px;
    background-color: #fff6f5;
    line-height: 24px;
    border: 4px solid #F21B1B;
    margin: 50px auto;
}

p {
    line-height: 1.5;
}

.StandardButton-1:focus {
    outline: 0;
}

.StandardButton-2:focus {
    outline: 0;
}

.StandardInput-1:focus {
    outline: 0;
}

button:focus {
    outline: none;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}

button, select {
    text-transform: none;
}

button, input {
    overflow: visible;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}

/*! CSS Used from: https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css */
*, ::after, ::before {
    box-sizing: border-box;
}


ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

small {
    font-size: 80%;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

button {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button {
    overflow: visible;
}

button {
    text-transform: none;
}

[type=button], button {
    -webkit-appearance: button;
}

[type=button]::-moz-focus-inner, button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

small {
    font-size: 80%;
    font-weight: 400;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: .75rem;
}

.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

.StandardForm {
    max-width: 600px;
    margin: 0 auto;
}


.StandardForm h1 {
    margin-bottom: 20px;
}


.StandardForm .form-group {
    margin-bottom: 20px;
}

.card-deck .card {
    margin-bottom: 15px;
}


@media (max-width: 800px) {
    h1 {
        font-size: 28px;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 18px;
    }
}

@media (min-width: 576px) {
    .card-deck {
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .card-deck .card {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.StandardTextarea-1 {
    height: 150px;
    border: 1px solid #ced4da;
}
.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

strong {
    font-weight: 700;
}

.StandardButton-5 {
    border-radius: 5px;
    font-weight: 700;
    padding: 20px;
    font-size: 1em;
    line-height: 1.133;
    -webkit-font-smoothing: antialiased;
    transition: 150ms ease;
    transition-property: background-color, border-color, box-shadow, color;
    text-align: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,0.15);
    background-color: #32A8EE;
    color: #ffffff;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
}

.StandardButton-5:hover {
    color: white;
}

.StandardButton-3 {
    border: 1px solid #15aeec;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 18px;
    padding: 10px 10px 10px 10px;
    text-decoration: none;
    display: inline-block;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.3);
    font-weight: bold;
    outline: none;
    color: #FFFFFF;
    width: 180px;

    background-color: #54C9EE;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#54C9EE), to(#32A8EE));
    background-image: -webkit-linear-gradient(top, #54C9EE, #32A8EE);
    background-image: -moz-linear-gradient(top, #54C9EE, #32A8EE);
    background-image: -ms-linear-gradient(top, #54C9EE, #32A8EE);
    background-image: -o-linear-gradient(top, #54C9EE, #32A8EE);
    background-image: linear-gradient(to bottom, #54C9EE, #32A8EE);

    /*background-color: #49c0f0;*/
    /*background-image: -webkit-gradient(linear, left top, left bottom, from(#49c0f0), to(#2CAFE3));*/
    /*background-image: -webkit-linear-gradient(top, #49c0f0, #2CAFE3);*/
    /*background-image: -moz-linear-gradient(top, #49c0f0, #2CAFE3);*/
    /*background-image: -ms-linear-gradient(top, #49c0f0, #2CAFE3);*/
    /*background-image: -o-linear-gradient(top, #49c0f0, #2CAFE3);*/
    /*background-image: linear-gradient(to bottom, #49c0f0, #2CAFE3);*/
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#49c0f0, endColorstr=#2CAFE3);
}

.StandardButton-3:hover {
    border: 1px solid #1090c3;
    background-color: #1ab0ec;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#1ab0ec), to(#1a92c2));
    background-image: -webkit-linear-gradient(top, #1ab0ec, #1a92c2);
    background-image: -moz-linear-gradient(top, #1ab0ec, #1a92c2);
    background-image: -ms-linear-gradient(top, #1ab0ec, #1a92c2);
    background-image: -o-linear-gradient(top, #1ab0ec, #1a92c2);
    background-image: linear-gradient(to bottom, #1ab0ec, #1a92c2);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#1ab0ec, endColorstr=#1a92c2);
}

.my-0 {
    margin-top: 0 !important;
}

.my-0 {
    margin-bottom: 0 !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.Inner {
    max-width: 50%;
}

.text-center {
    text-align: center !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.text-muted {
    color: #6c757d !important;
}

@media print {
    *, ::after, ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }
}

/*! CSS Used from: Embedded */
div, h1, h4, small, ul, li {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

ul {
    list-style: none;
}

div {
    font-size: 16px;
    line-height: 24px;
}

.Main h1 {
    font-size: 32px;
    line-height: 1.4;
}

.Main {
    max-width: 700px;
    margin: 0 auto;
}



.Form textarea {
    height: 250px;
}

.Form form label {
    margin: 20px 0;
}

.Form button {
    margin: 20px 0;
    background-color: #32A8EE;
    border: 1px solid #fff;
    color: #fff;
    font-weight: bold;
    border-radius: 40px;
    padding: 10px 20px;
    outline: none;
}

.Form input {
    border: 1px solid #888888;
}

.StandardButton-2 {
    background-color: #32A8EE;
    border: 1px solid #fff;
    color: #fff;
    font-weight: bold;
    border-radius: 40px;
    padding: 10px 20px;
    outline: none;
}

.StandardForm button {
    background-color: #32A8EE;
    border: 1px solid #fff;
    color: #fff;
    font-weight: bold;
    border-radius: 40px;
    padding: 10px 20px;
    outline: none;
    margin: 10px auto;
    text-align: center;
}

.LoadMore {
    border: 1px solid #32A8EE;
    padding: 20px;
    background: white;
    color: #32A8EE;
}

.ButtonStandard {
    background-color: #32A8EE;
}


.ButtonStandard:hover {
    color: #ffe506;
}

button {
    outline: none;
}

.StandardButton-6 {
    padding: 15px 25px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 33px;
    background-color: #32a8ee;
    color: white;
    outline: none;
}

a.StandardButton-6:hover {
    color: white;
}

.StandardButton-7 {
    padding: 15px 25px;
    font-weight: bold;
    cursor: pointer;
    background-color: transparent;
    border-radius: 33px;
    border: 2px solid #32a8ee;
    color: #32a8ee;
    outline: none;
}

.StandardButton-8 {
    padding: 15px 25px;
    font-weight: bold;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #32a8ee;
    outline: none;
}

.StandardButton-2:focus {
    outline: 0;
}

.Inner {
    width: 50%;
    margin: 0 auto;
}

button:focus {
    outline: none;
}

[type=button], button {
    -webkit-appearance: button;
}

button {
    text-transform: none;
}

button {
    overflow: visible;
}

button {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}


*, ::after, ::before {
    box-sizing: border-box;
}

.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}

@media print {
    *, ::after, ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }
}

/*! CSS Used from: https://getbootstrap.com/docs/4.0/dist/css/bootstrap.min.css */
*, ::after, ::before {
    box-sizing: border-box;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0;
}



.alert-warning {
    background-image: url('../../assets/images/warning.svg') !important;
    background-position: 31px center !important;
    background-size: 24px 24px !important;
    background-repeat: no-repeat !important;
    padding: 24px 27px 24px 65px !important;
    color: black;
    background-color: white;
    border: 1px solid #FE0303 !important;
    border-radius: 12px !important;
    line-height: 145%;
}


.alert-success {
    background-image: url('../../assets/images/success.svg') !important;
    background-position: 31px center !important;
    background-size: 24px 24px !important;
    background-repeat: no-repeat !important;
    background-color: white;
    padding: 24px 27px 24px 65px !important;
    color: black;
    border: 1px solid #0F9525 !important;
    line-height: 145%;
    border-radius: 12px !important;
}


.alert-info {
    background-image: url('../../assets/images/info.svg') !important;
    background-position: 31px center !important;
    background-size: 24px 24px !important;
    background-repeat: no-repeat !important;
    color: black;
    background-color: white;
    line-height: 145%;
    padding: 24px 27px 24px 65px !important;
    border: 1px solid #65ACF1 !important;
    border-radius: 12px !important;
}

/*! CSS Used from: https://getbootstrap.com/docs/4.0/assets/css/docs.min.css */
.bd-example > .alert + .alert {
    margin-top: 1rem;
}

/*! CSS Used from: https://getbootstrap.com/docs/4.0/assets/css/docs.min.css */
.bd-example > .alert + .alert {
    margin-top: 1rem;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

/*! CSS Used from: https://getbootstrap.com/docs/4.0/assets/css/docs.min.css */
.bd-example > .alert + .alert {
    margin-top: 1rem;
}

.Form {
    margin: 20px auto;
}

.Form input {
    margin-bottom: 10px;
}