.header {
    padding: 20px 20px 0 20px;
    margin: 0 auto;
    margin-bottom: 10px;
    width: 100%;
    z-index: 1000;
    height: 90px;
    position: sticky;
}

.header_nav_login {
    width: 140px;
    height: 50px;
    background: #32a8ee;
    border-radius: 8px;
    color: #fff !important;
    text-align: center !important;
}

.header a {
    display: inline-block;
    padding: 14px 16px 0 16px;
    text-decoration: none;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;

    font-weight: 400;
    font-size: 17px;
    line-height: 145%;
    /* or 20px */


    color: #023047;
}

a.startForFree {
    width: 148px;
    height: 43px;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    /* or 20px */
    text-align: center;


    color: #FFFFFF;

    background: #41AAE8;
    border-radius: 60px;
}

a.startForFree:hover {
    text-decoration: none;
    color: white;
}

.loginWrapper {
    display: inline-block;
}

.header a.startNow {
    color: white;
}
.header a.startNow:hover {
    color: white;
}

/* Style the header links */
.header a.logo {
    text-align: center;
    padding: 0 12px 12px 12px;
    text-decoration: none;
    background: url("../../../assets/images/logoi.png") left top no-repeat;
    background-size: 170px auto;
    width: 33%;
    min-width: 170px;
    height: 63px;
}

.header .signup {
    border-radius: 60px;
    background-color: #41AAE8;
    color: #fff;
    font-weight: 400;
    width: 148px;
    height: 43px;
    text-align: center;
    margin-left: 16px;
}

.header a:hover {
    color: #32A8EE;
}

.header a.signup:hover {
    color: #ffffff;
}

a.login {
    margin-left: 20px;
}
a.pricing {
    border-radius: 5px;
    font-weight: 700;
    font-size: 1em;
    line-height: 1.133;
    -webkit-font-smoothing: antialiased;
    transition: 150ms ease;
    transition-property: background-color, border-color, box-shadow, color;
    text-align: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,0.15);
    background-color: #32A8EE;
    color: #ffffff;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
}

a.pricing:hover {
    color: #ffe506;
}

a.login:focus {
    outline:0;
}

.header a.active {
    background-color: #4CAF50;
    color: black;
}

.header .icon {
    display: none;
}

div.line {
    width: 30px;
    height: 4px;
    background-color: black;
    margin: 6px 0;
}

.dropbtn {
    cursor: pointer;
    border: none;
    background-color: transparent;
    background-image: url(../../../assets/images/user.png);
    background-position: center center;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background-size: contain;
}


.header .icon {
    display: none;
}

div.line {
    width: 30px;
    height: 4px;
    background-color: black;
    margin: 6px 0;
}


/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

.floatingBannner {
    background-color: #d7e9ff;
    min-height: 50px;
    width: 100%;
}

.floatingBannner-message {
    display: inline-block;
    vertical-align: top;
    margin: 15px;
    width: calc(100vw - 100px);
    line-height: 1.4;
}

.floatingBannner-close {
    display: inline-block;
    border: none;
    margin-top: 10px;
    vertical-align: top;
    padding: 5px;
    font-size: 20px;
    cursor: pointer;
}

.floatingBannner a {
    color: #123048;
    font-weight: bold;
    text-decoration: underline;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff !important;
    box-shadow: 0px 3px 5px -1px #ccc;
    -webkit-box-shadow: 0px 3px 5px -1px #ccc;
    border-radius: 0;
    padding: 10px 17px !important;
    border: none !important;
    min-width: 190px;
    z-index: 1001;
}

/* Links inside the dropdown */
.dropdown-content a {
    padding: 12px 16px;
    text-decoration: none;
    color: #1b2125;
    display: block;
    width: 100%;
    text-align: left;

}


/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #fff;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

.header a.icon {
    padding: 0
}

.logindropdown {
    padding-top: 8px;
    padding-left: 10px;
    margin-left: 100px;
}

.Header_DropdownMenu {
    display: inline-block;
}

@media screen and (max-width: 1115px) {

    .header {
        display: block;
    }
    .header a.logo {
        width: 90%;
    }
    .header a:not(:first-child) {display: none;}
    .header > div:not(:first-child) {display: none;}
    .header a.icon {
        float: right;
        display: block;
    }

    .header a.icon {
        position: absolute;
        right: 5%;
        top: 25%;
    }
    .header.responsive a {
        float: none;
        display: block;
        text-align: left;
        background-color: #fff;
        color: black;
        font-weight: normal;
    }

    .header.responsive a.logo {
        background-color: white;
    }

    .header.responsive a.icon {
        background-color: white;
    }


    .header.responsive a.login {
        text-align: left;
        margin: 0;
    }
    .header.responsive .signup {
        text-align: left;
        margin: 0;
        padding: 14px 16px 16px 16px;
        width: 100%;
        border-radius: 0;
    }
    .Header_DropdownMenu {
        background-color: #fff !important;
        /* Drop Shadow */

        box-shadow: 0px 3px 5px -1px #ccc;
        -webkit-box-shadow: 0px 3px 5px -1px #ccc;
        border-radius: 12px !important;
        padding: 10px 17px !important;
        border: none !important;
        display: none;
    }

    .header.responsive .Header_DropdownMenu {
        display: block;
    }

    .header.responsive .signup:hover {
        color: #000000;
    }

    .header.responsive > div {
        float: none;
        display: block;
        text-align: left;
        background-color: white;
    }

    .dropdown {
        margin-left: 0px;
    }

    .header.responsive {
        background-color: white;
    }

    a.logo {
        background-color: white;
        margin-bottom: 0px;
    }

    a.login {
        border: 0;
        margin-left: 0;
    }

    a.pricing {
        background-color: #8155fb;
        font-weight: normal;
        color: black;
        box-shadow: none;
    }

    a.pricing:hover {
        color: #32A8EE;
        font-weight: bold;
    }
}

@media  screen and (max-width: 500px) {
    .header_nav_login {
        background: none;
        border-radius: 0;
        color: #000 !important;
        text-align: left !important;
    }
}