.ChooseCategories {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
}

.ChooseCategories h1 {
    margin-bottom: 20px;
}

.ChooseCategories .subtitle {
    margin-bottom: 40px;
}

.ChooseCategories_SubmitBtn {
    margin: 0 auto;
    text-align: center;
}

.ChooseCategories_Category {
    border: 1px solid #dddddd;
    padding: 10px;
    border-radius: 12px;
    text-align: left;
    margin-bottom: 10px;
}