.Referrals_Box {
    padding: 30px;
    border: 1px #eeeeee solid;
    margin: 30px 0;
}

.Referrals_LinkShare {
    margin: 0px 0 20px 0;
}

.Referrals_LinkShareTd {
    margin: 20px 0 0 0;
}

.Refer {
    max-width: 900px;
}

.registration-form{
    padding: 50px 0;
    text-align: center;
}

.registration-form form{
    background-color: #fff;
    max-width: 600px;
    margin: auto;
    padding: 50px 70px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
}

.already-a-user {
    margin: 20px auto;
}
.forgot-password {
    margin: 20px auto;
}
.registration-form .form-icon{
    text-align: center;
    background-color: #5891ff;
    border-radius: 50%;
    font-size: 40px;
    color: white;
    width: 100px;
    height: 100px;
    margin: auto;
    margin-bottom: 50px;
    line-height: 100px;
}

.registration-form .item{
    border-radius: 20px;
    margin-bottom: 25px;
    padding: 10px 20px;
}

.registration-form .create-account{
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    background-color: #5791ff;
    border: none;
    color: white;
    margin-top: 20px;
}

.registration-form .social-icons{
    margin-top: 30px;
    margin-bottom: 16px;
}

.registration-form .social-icons a{
    font-size: 23px;
    margin: 0 3px;
    color: #5691ff;
    border: 1px solid;
    border-radius: 50%;
    width: 45px;
    display: inline-block;
    height: 45px;
    text-align: center;
    background-color: #fff;
    line-height: 45px;
}

.registration-form .social-icons a:hover{
    text-decoration: none;
    opacity: 0.6;
}

@media (max-width: 576px) {
    .registration-form form{
        padding: 50px 20px;
    }

    .registration-form .form-icon{
        width: 70px;
        height: 70px;
        font-size: 30px;
        line-height: 70px;
    }
}

.Refer p {
    margin: 20px ;
    text-align: center;
}
.Refer ul {
    list-style-type: disc;
}

.Refer ol {
    list-style-type: decimal;
}


.Refer_Body {
    padding: 30px;
}

.Refer h3 {
    margin-bottom: 20px;
}

.Refer h1 {
    text-align: center;
}
.Refer_Header {
    background-image: url(../../assets/images/refer.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    background-position: center;
    height: 290px;
}

.Referrals_StatsItem  {
    margin: 30px 0;
    padding: 0;
}

.Referrals_LinkTd {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    padding: 0;
}

.Referrals_ShareLink {
    text-align: center;
    background-color: #dddddd;
    padding: 20px;
}

.Referrals_Link {
    padding: 20px;
    border: 1px #eeeeee solid;
}


.Login {
    margin-bottom: 100px;
    margin-top: 40px;
}

@media (max-width: 991.98px) {
    .Login .half .bg {
        height: 500px;
    }
}

.ForgotPassword, .ResetPassword, .UserUpdate {
    max-width: 600px;
}

.Login_BookDetail {
    margin-bottom: 30px;
}

.Login_BookDetail h3 {
    margin: 20px 0;
}

.Login_BookDetail li {
    margin: 10px 0;
    line-height: 1.5;
}

.Login_Error {
    margin-bottom: 30px;
}

.Login .half .contents, .Login .half .bg {
    width: 50%;
}

.Login .signUpImg {
    background-image: url(../../assets/images/signUp.jpg);
}

@media (max-width: 1199.98px) {
    .Login .half .contents, .Login .half .bg {
        width: 100%;
    }

    .Login .half, .Login .half .container > .row {
        height: auto;
        padding-top: 30px;
    }
}

.Login .half .contents .form-group, .Login .half .bg .form-group {
    overflow: hidden;
    margin-bottom: 0;
    border: 1px solid #efefef;
    padding: 15px 15px;
    border-bottom: none;
    position: relative;
}

.Login .half .contents .form-group label, .Login .half .bg .form-group label {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

.Login .half .contents .form-group input:focus + label, .Login .half .bg .form-group input:focus + label {
    margin-top: -20px;
}

.Login .half .contents .form-group.first, .Login .half .bg .form-group.first {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.Login .half .contents .form-group.last, .Login .half .bg .form-group.last {
    border-bottom: 1px solid #efefef;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.Login .half .contents .form-group label, .Login .half .bg .form-group label {
    font-size: 12px;
    display: block;
    margin-bottom: 0;
    color: #b3b3b3;
}

.Login .half .contents .form-group.field--not-empty label, .Login .half .bg .form-group.field--not-empty label {
    margin-top: -20px;
}

.Login .half .contents .form-control, .Login .half .bg .form-control {
    border: none;
    padding: 0;
    font-size: 16px;
    border-radius: 0;
}

.Login .half .contents .form-control:active, .Login .half .contents .form-control:focus, .Login .half .bg .form-control:active, .Login .half .bg .form-control:focus {
    outline: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.profile-form .react-tel-input .form-control {
    border-radius: 0px;
    width: 100%;
    margin-bottom: 25px;
}

.profile-form .react-tel-input {
    margin-top: 10px !important;
}

.Login .half .bg {
    background-size: cover;
    background-position: center;
}

.Login .half a {
    color: #888;
    text-decoration: underline;
}

.Login .half .btn {
    height: 54px;
    padding-left: 30px;
    padding-right: 30px;
}

.Login .half .forgot-pass {
    position: relative;
    top: 2px;
    font-size: 14px;
}

.Login .social-login a {
    text-decoration: none;
    display: block;
    position: relative;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
    padding: 10px;
}

.Login .social-login a:hover {
    color: #fff;
}

.Login .social-login a.facebook {
    background: #3b5998;
}

.Login .social-login a.facebook:hover {
    background: #344e86;
}

.Login .social-login a.twitter {
    background: #1da1f2;
}

.Login .social-login a.twitter:hover {
    background: #0d95e8;
}

.Login .social-login a.google {
    background: #ea4335;
}


.Login .social-login a.apple {
    background: #000000;
}


.Login .social-login a.google:hover {
    background: #e82e1e;
}

.Login .control {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 14px;
}

.Login .control .caption {
    position: relative;
    top: .2rem;
    color: #888;
}

.Login .control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.Login .control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #e6e6e6;
    border-radius: 4px;
}

.Login .control--radio .control__indicator {
    border-radius: 50%;
}

.Login .control:hover input ~ .control__indicator,
.Login .control input:focus ~ .control__indicator {
    background: #ccc;
}

.Login .control input:checked ~ .control__indicator {
    background: #007bff;
}

.Login .control:hover input:not([disabled]):checked ~ .control__indicator,
.Login .control input:checked:focus ~ .control__indicator {
    background: #1a88ff;
}

.Login .control input:disabled ~ .control__indicator {
    background: #e6e6e6;
    opacity: 0.9;
    pointer-events: none;
}

.Login .submitLoginBtn {
    color: #fff;
    background-color: #007bff;
    border: none;
    width: 100%;
    padding: 10px;
    display: block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.Login .social-login a.linkedin {
    background-color: #0073b1;
}

.Login .niceBtn {
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}


.Login .control__indicator:after {
    font-family: 'icomoon';
    content: '\e5ca';
    position: absolute;
    display: none;
    font-size: 16px;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

.Login .control input:checked ~ .control__indicator:after {
    display: block;
    color: #fff;
}

.Login .control--checkbox .control__indicator:after {
    top: 50%;
    left: 50%;
    margin-top: -1px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.Login .control--checkbox input:disabled ~ .control__indicator:after {
    border-color: #7b7b7b;
}

.Login .control--checkbox input:disabled:checked ~ .control__indicator {
    background-color: #7e0cf5;
    opacity: .2;
}

.Login .googleBtn {
    background-image: url(../../assets/images/btn_google_signin_light_normal_web@2x.png);
    background-size: auto 46px;
    width: 100%;
    height: 46px;
    background-repeat: no-repeat;
    border: none;
    background-color: transparent;
}

@font-face {
    font-family: 'icomoon';
    src: url('../../assets/fonts/icomoon/icomoon.eot?10si43');
    src: url('../../assets/fonts/icomoon/icomoon.eot?10si43#iefix') format('embedded-opentype'),
    url('../../assets/fonts/icomoon/icomoon.ttf?10si43') format('truetype'),
    url('../../assets/fonts/icomoon/icomoon.woff?10si43') format('woff'),
    url('../../assets/fonts/icomoon/icomoon.svg?10si43#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="s-icon-"], [class*=" s-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.s-icon-asterisk:before {
    content: "\f069";
}

.s-icon-plus:before {
    content: "\f067";
}

.s-icon-question:before {
    content: "\f128";
}

.s-icon-minus:before {
    content: "\f068";
}

.s-icon-glass:before {
    content: "\f000";
}

.s-icon-music:before {
    content: "\f001";
}

.s-icon-search:before {
    content: "\f002";
}

.s-icon-envelope-o:before {
    content: "\f003";
}

.s-icon-heart:before {
    content: "\f004";
}

.s-icon-star:before {
    content: "\f005";
}

.s-icon-star-o:before {
    content: "\f006";
}

.s-icon-user:before {
    content: "\f007";
}

.s-icon-film:before {
    content: "\f008";
}

.s-icon-th-large:before {
    content: "\f009";
}

.s-icon-th:before {
    content: "\f00a";
}

.s-icon-th-list:before {
    content: "\f00b";
}

.s-icon-check:before {
    content: "\f00c";
}

.s-icon-close:before {
    content: "\f00d";
}

.s-icon-remove:before {
    content: "\f00d";
}

.s-icon-times:before {
    content: "\f00d";
}

.s-icon-search-plus:before {
    content: "\f00e";
}

.s-icon-search-minus:before {
    content: "\f010";
}

.s-icon-power-off:before {
    content: "\f011";
}

.s-icon-signal:before {
    content: "\f012";
}

.s-icon-cog:before {
    content: "\f013";
}

.s-icon-gear:before {
    content: "\f013";
}

.s-icon-trash-o:before {
    content: "\f014";
}

.s-icon-home:before {
    content: "\f015";
}

.s-icon-file-o:before {
    content: "\f016";
}

.s-icon-clock-o:before {
    content: "\f017";
}

.s-icon-road:before {
    content: "\f018";
}

.s-icon-download:before {
    content: "\f019";
}

.s-icon-arrow-circle-o-down:before {
    content: "\f01a";
}

.s-icon-arrow-circle-o-up:before {
    content: "\f01b";
}

.s-icon-inbox:before {
    content: "\f01c";
}

.s-icon-play-circle-o:before {
    content: "\f01d";
}

.s-icon-repeat:before {
    content: "\f01e";
}

.s-icon-rotate-right:before {
    content: "\f01e";
}

.s-icon-refresh:before {
    content: "\f021";
}

.s-icon-list-alt:before {
    content: "\f022";
}

.s-icon-lock:before {
    content: "\f023";
}

.s-icon-flag:before {
    content: "\f024";
}

.s-icon-headphones:before {
    content: "\f025";
}

.s-icon-volume-off:before {
    content: "\f026";
}

.s-icon-volume-down:before {
    content: "\f027";
}

.s-icon-volume-up:before {
    content: "\f028";
}

.s-icon-qrcode:before {
    content: "\f029";
}

.s-icon-barcode:before {
    content: "\f02a";
}

.s-icon-tag:before {
    content: "\f02b";
}

.s-icon-tags:before {
    content: "\f02c";
}

.s-icon-book:before {
    content: "\f02d";
}

.s-icon-bookmark:before {
    content: "\f02e";
}

.s-icon-print:before {
    content: "\f02f";
}

.s-icon-camera:before {
    content: "\f030";
}

.s-icon-font:before {
    content: "\f031";
}

.s-icon-bold:before {
    content: "\f032";
}

.s-icon-italic:before {
    content: "\f033";
}

.s-icon-text-height:before {
    content: "\f034";
}

.s-icon-text-width:before {
    content: "\f035";
}

.s-icon-align-left:before {
    content: "\f036";
}

.s-icon-align-center:before {
    content: "\f037";
}

.s-icon-align-right:before {
    content: "\f038";
}

.s-icon-align-justify:before {
    content: "\f039";
}

.s-icon-list:before {
    content: "\f03a";
}

.s-icon-dedent:before {
    content: "\f03b";
}

.s-icon-outdent:before {
    content: "\f03b";
}

.s-icon-indent:before {
    content: "\f03c";
}

.s-icon-video-camera:before {
    content: "\f03d";
}

.s-icon-image:before {
    content: "\f03e";
}

.s-icon-photo:before {
    content: "\f03e";
}

.s-icon-picture-o:before {
    content: "\f03e";
}

.s-icon-pencil:before {
    content: "\f040";
}

.s-icon-map-marker:before {
    content: "\f041";
}

.s-icon-adjust:before {
    content: "\f042";
}

.s-icon-tint:before {
    content: "\f043";
}

.s-icon-edit:before {
    content: "\f044";
}

.s-icon-pencil-square-o:before {
    content: "\f044";
}

.s-icon-share-square-o:before {
    content: "\f045";
}

.s-icon-check-square-o:before {
    content: "\f046";
}

.s-icon-arrows:before {
    content: "\f047";
}

.s-icon-step-backward:before {
    content: "\f048";
}

.s-icon-fast-backward:before {
    content: "\f049";
}

.s-icon-backward:before {
    content: "\f04a";
}

.s-icon-play:before {
    content: "\f04b";
}

.s-icon-pause:before {
    content: "\f04c";
}

.s-icon-stop:before {
    content: "\f04d";
}

.s-icon-forward:before {
    content: "\f04e";
}

.s-icon-fast-forward:before {
    content: "\f050";
}

.s-icon-step-forward:before {
    content: "\f051";
}

.s-icon-eject:before {
    content: "\f052";
}

.s-icon-chevron-left:before {
    content: "\f053";
}

.s-icon-chevron-right:before {
    content: "\f054";
}

.s-icon-plus-circle:before {
    content: "\f055";
}

.s-icon-minus-circle:before {
    content: "\f056";
}

.s-icon-times-circle:before {
    content: "\f057";
}

.s-icon-check-circle:before {
    content: "\f058";
}

.s-icon-question-circle:before {
    content: "\f059";
}

.s-icon-info-circle:before {
    content: "\f05a";
}

.s-icon-crosshairs:before {
    content: "\f05b";
}

.s-icon-times-circle-o:before {
    content: "\f05c";
}

.s-icon-check-circle-o:before {
    content: "\f05d";
}

.s-icon-ban:before {
    content: "\f05e";
}

.s-icon-arrow-left:before {
    content: "\f060";
}

.s-icon-arrow-right:before {
    content: "\f061";
}

.s-icon-arrow-up:before {
    content: "\f062";
}

.s-icon-arrow-down:before {
    content: "\f063";
}

.s-icon-mail-forward:before {
    content: "\f064";
}

.s-icon-share:before {
    content: "\f064";
}

.s-icon-expand:before {
    content: "\f065";
}

.s-icon-compress:before {
    content: "\f066";
}

.s-icon-exclamation-circle:before {
    content: "\f06a";
}

.s-icon-gift:before {
    content: "\f06b";
}

.s-icon-leaf:before {
    content: "\f06c";
}

.s-icon-fire:before {
    content: "\f06d";
}

.s-icon-eye:before {
    content: "\f06e";
}

.s-icon-eye-slash:before {
    content: "\f070";
}

.s-icon-exclamation-triangle:before {
    content: "\f071";
}

.s-icon-warning:before {
    content: "\f071";
}

.s-icon-plane:before {
    content: "\f072";
}

.s-icon-calendar:before {
    content: "\f073";
}

.s-icon-random:before {
    content: "\f074";
}

.s-icon-comment:before {
    content: "\f075";
}

.s-icon-magnet:before {
    content: "\f076";
}

.s-icon-chevron-up:before {
    content: "\f077";
}

.s-icon-chevron-down:before {
    content: "\f078";
}

.s-icon-retweet:before {
    content: "\f079";
}

.s-icon-shopping-cart:before {
    content: "\f07a";
}

.s-icon-folder:before {
    content: "\f07b";
}

.s-icon-folder-open:before {
    content: "\f07c";
}

.s-icon-arrows-v:before {
    content: "\f07d";
}

.s-icon-arrows-h:before {
    content: "\f07e";
}

.s-icon-bar-chart:before {
    content: "\f080";
}

.s-icon-bar-chart-o:before {
    content: "\f080";
}

.s-icon-twitter-square:before {
    content: "\f081";
}

.s-icon-facebook-square:before {
    content: "\f082";
}

.s-icon-camera-retro:before {
    content: "\f083";
}

.s-icon-key:before {
    content: "\f084";
}

.s-icon-cogs:before {
    content: "\f085";
}

.s-icon-gears:before {
    content: "\f085";
}

.s-icon-comments:before {
    content: "\f086";
}

.s-icon-thumbs-o-up:before {
    content: "\f087";
}

.s-icon-thumbs-o-down:before {
    content: "\f088";
}

.s-icon-star-half:before {
    content: "\f089";
}

.s-icon-heart-o:before {
    content: "\f08a";
}

.s-icon-sign-out:before {
    content: "\f08b";
}

.s-icon-linkedin-square:before {
    content: "\f08c";
}

.s-icon-thumb-tack:before {
    content: "\f08d";
}

.s-icon-external-link:before {
    content: "\f08e";
}

.s-icon-sign-in:before {
    content: "\f090";
}

.s-icon-trophy:before {
    content: "\f091";
}

.s-icon-github-square:before {
    content: "\f092";
}

.s-icon-upload:before {
    content: "\f093";
}

.s-icon-lemon-o:before {
    content: "\f094";
}

.s-icon-phone:before {
    content: "\f095";
}

.s-icon-square-o:before {
    content: "\f096";
}

.s-icon-bookmark-o:before {
    content: "\f097";
}

.s-icon-phone-square:before {
    content: "\f098";
}

.s-icon-twitter:before {
    content: "\f099";
}

.s-icon-facebook:before {
    content: "\f09a";
    padding: 20px;
}

.s-icon-facebook-f:before {
    content: "\f09a";
}

.s-icon-github:before {
    content: "\f09b";
}

.s-icon-unlock:before {
    content: "\f09c";
}

.s-icon-credit-card:before {
    content: "\f09d";
}

.s-icon-feed:before {
    content: "\f09e";
}

.s-icon-rss:before {
    content: "\f09e";
}

.s-icon-hdd-o:before {
    content: "\f0a0";
}

.s-icon-bullhorn:before {
    content: "\f0a1";
}

.s-icon-bell-o:before {
    content: "\f0a2";
}

.s-icon-certificate:before {
    content: "\f0a3";
}

.s-icon-hand-o-right:before {
    content: "\f0a4";
}

.s-icon-hand-o-left:before {
    content: "\f0a5";
}

.s-icon-hand-o-up:before {
    content: "\f0a6";
}

.s-icon-hand-o-down:before {
    content: "\f0a7";
}

.s-icon-arrow-circle-left:before {
    content: "\f0a8";
}

.s-icon-arrow-circle-right:before {
    content: "\f0a9";
}

.s-icon-arrow-circle-up:before {
    content: "\f0aa";
}

.s-icon-arrow-circle-down:before {
    content: "\f0ab";
}

.s-icon-globe:before {
    content: "\f0ac";
}

.s-icon-wrench:before {
    content: "\f0ad";
}

.s-icon-tasks:before {
    content: "\f0ae";
}

.s-icon-filter:before {
    content: "\f0b0";
}

.s-icon-briefcase:before {
    content: "\f0b1";
}

.s-icon-arrows-alt:before {
    content: "\f0b2";
}

.s-icon-group:before {
    content: "\f0c0";
}

.s-icon-users:before {
    content: "\f0c0";
}

.s-icon-chain:before {
    content: "\f0c1";
}

.s-icon-link:before {
    content: "\f0c1";
}

.s-icon-cloud:before {
    content: "\f0c2";
}

.s-icon-flask:before {
    content: "\f0c3";
}

.s-icon-cut:before {
    content: "\f0c4";
}

.s-icon-scissors:before {
    content: "\f0c4";
}

.s-icon-copy:before {
    content: "\f0c5";
}

.s-icon-files-o:before {
    content: "\f0c5";
}

.s-icon-paperclip:before {
    content: "\f0c6";
}

.s-icon-floppy-o:before {
    content: "\f0c7";
}

.s-icon-save:before {
    content: "\f0c7";
}

.s-icon-square:before {
    content: "\f0c8";
}

.s-icon-bars:before {
    content: "\f0c9";
}

.s-icon-navicon:before {
    content: "\f0c9";
}

.s-icon-reorder:before {
    content: "\f0c9";
}

.s-icon-list-ul:before {
    content: "\f0ca";
}

.s-icon-list-ol:before {
    content: "\f0cb";
}

.s-icon-strikethrough:before {
    content: "\f0cc";
}

.s-icon-underline:before {
    content: "\f0cd";
}

.s-icon-table:before {
    content: "\f0ce";
}

.s-icon-magic:before {
    content: "\f0d0";
}

.s-icon-truck:before {
    content: "\f0d1";
}

.s-icon-pinterest:before {
    content: "\f0d2";
}

.s-icon-pinterest-square:before {
    content: "\f0d3";
}

.s-icon-google-plus-square:before {
    content: "\f0d4";
}

.s-icon-google-plus:before {
    content: "\f0d5";
}

.s-icon-money:before {
    content: "\f0d6";
}

.s-icon-caret-down:before {
    content: "\f0d7";
}

.s-icon-caret-up:before {
    content: "\f0d8";
}

.s-icon-caret-left:before {
    content: "\f0d9";
}

.s-icon-caret-right:before {
    content: "\f0da";
}

.s-icon-columns:before {
    content: "\f0db";
}

.s-icon-sort:before {
    content: "\f0dc";
}

.s-icon-unsorted:before {
    content: "\f0dc";
}

.s-icon-sort-desc:before {
    content: "\f0dd";
}

.s-icon-sort-down:before {
    content: "\f0dd";
}

.s-icon-sort-asc:before {
    content: "\f0de";
}

.s-icon-sort-up:before {
    content: "\f0de";
}

.s-icon-envelope:before {
    content: "\f0e0";
}

.s-icon-linkedin:before {
    content: "\f0e1";
    padding-right: 20px;
}

.s-icon-rotate-left:before {
    content: "\f0e2";
}

.s-icon-undo:before {
    content: "\f0e2";
}

.s-icon-gavel:before {
    content: "\f0e3";
}

.s-icon-legal:before {
    content: "\f0e3";
}

.s-icon-dashboard:before {
    content: "\f0e4";
}

.s-icon-tachometer:before {
    content: "\f0e4";
}

.s-icon-comment-o:before {
    content: "\f0e5";
}

.s-icon-comments-o:before {
    content: "\f0e6";
}

.s-icon-bolt:before {
    content: "\f0e7";
}

.s-icon-flash:before {
    content: "\f0e7";
}

.s-icon-sitemap:before {
    content: "\f0e8";
}

.s-icon-umbrella:before {
    content: "\f0e9";
}

.s-icon-clipboard:before {
    content: "\f0ea";
}

.s-icon-paste:before {
    content: "\f0ea";
}

.s-icon-lightbulb-o:before {
    content: "\f0eb";
}

.s-icon-exchange:before {
    content: "\f0ec";
}

.s-icon-cloud-download:before {
    content: "\f0ed";
}

.s-icon-cloud-upload:before {
    content: "\f0ee";
}

.s-icon-user-md:before {
    content: "\f0f0";
}

.s-icon-stethoscope:before {
    content: "\f0f1";
}

.s-icon-suitcase:before {
    content: "\f0f2";
}

.s-icon-bell:before {
    content: "\f0f3";
}

.s-icon-coffee:before {
    content: "\f0f4";
}

.s-icon-cutlery:before {
    content: "\f0f5";
}

.s-icon-file-text-o:before {
    content: "\f0f6";
}

.s-icon-building-o:before {
    content: "\f0f7";
}

.s-icon-hospital-o:before {
    content: "\f0f8";
}

.s-icon-ambulance:before {
    content: "\f0f9";
}

.s-icon-medkit:before {
    content: "\f0fa";
}

.s-icon-fighter-jet:before {
    content: "\f0fb";
}

.s-icon-beer:before {
    content: "\f0fc";
}

.s-icon-h-square:before {
    content: "\f0fd";
}

.s-icon-plus-square:before {
    content: "\f0fe";
}

.s-icon-angle-double-left:before {
    content: "\f100";
}

.s-icon-angle-double-right:before {
    content: "\f101";
}

.s-icon-angle-double-up:before {
    content: "\f102";
}

.s-icon-angle-double-down:before {
    content: "\f103";
}

.s-icon-angle-left:before {
    content: "\f104";
}

.s-icon-angle-right:before {
    content: "\f105";
}

.s-icon-angle-up:before {
    content: "\f106";
}

.s-icon-angle-down:before {
    content: "\f107";
}

.s-icon-desktop:before {
    content: "\f108";
}

.s-icon-laptop:before {
    content: "\f109";
}

.s-icon-tablet:before {
    content: "\f10a";
}

.s-icon-mobile:before {
    content: "\f10b";
}

.s-icon-mobile-phone:before {
    content: "\f10b";
}

.s-icon-circle-o:before {
    content: "\f10c";
}

.s-icon-quote-left:before {
    content: "\f10d";
}

.s-icon-quote-right:before {
    content: "\f10e";
}

.s-icon-spinner:before {
    content: "\f110";
}

.s-icon-circle:before {
    content: "\f111";
}

.s-icon-mail-reply:before {
    content: "\f112";
}

.s-icon-reply:before {
    content: "\f112";
}

.s-icon-github-alt:before {
    content: "\f113";
}

.s-icon-folder-o:before {
    content: "\f114";
}

.s-icon-folder-open-o:before {
    content: "\f115";
}

.s-icon-smile-o:before {
    content: "\f118";
}

.s-icon-frown-o:before {
    content: "\f119";
}

.s-icon-meh-o:before {
    content: "\f11a";
}

.s-icon-gamepad:before {
    content: "\f11b";
}

.s-icon-keyboard-o:before {
    content: "\f11c";
}

.s-icon-flag-o:before {
    content: "\f11d";
}

.s-icon-flag-checkered:before {
    content: "\f11e";
}

.s-icon-terminal:before {
    content: "\f120";
}

.s-icon-code:before {
    content: "\f121";
}

.s-icon-mail-reply-all:before {
    content: "\f122";
}

.s-icon-reply-all:before {
    content: "\f122";
}

.s-icon-star-half-empty:before {
    content: "\f123";
}

.s-icon-star-half-full:before {
    content: "\f123";
}

.s-icon-star-half-o:before {
    content: "\f123";
}

.s-icon-location-arrow:before {
    content: "\f124";
}

.s-icon-crop:before {
    content: "\f125";
}

.s-icon-code-fork:before {
    content: "\f126";
}

.s-icon-chain-broken:before {
    content: "\f127";
}

.s-icon-unlink:before {
    content: "\f127";
}

.s-icon-info:before {
    content: "\f129";
}

.s-icon-exclamation:before {
    content: "\f12a";
}

.s-icon-superscript:before {
    content: "\f12b";
}

.s-icon-subscript:before {
    content: "\f12c";
}

.s-icon-eraser:before {
    content: "\f12d";
}

.s-icon-puzzle-piece:before {
    content: "\f12e";
}

.s-icon-microphone:before {
    content: "\f130";
}

.s-icon-microphone-slash:before {
    content: "\f131";
}

.s-icon-shield:before {
    content: "\f132";
}

.s-icon-calendar-o:before {
    content: "\f133";
}

.s-icon-fire-extinguisher:before {
    content: "\f134";
}

.s-icon-rocket:before {
    content: "\f135";
}

.s-icon-maxcdn:before {
    content: "\f136";
}

.s-icon-chevron-circle-left:before {
    content: "\f137";
}

.s-icon-chevron-circle-right:before {
    content: "\f138";
}

.s-icon-chevron-circle-up:before {
    content: "\f139";
}

.s-icon-chevron-circle-down:before {
    content: "\f13a";
}

.s-icon-html5:before {
    content: "\f13b";
}

.s-icon-css3:before {
    content: "\f13c";
}

.s-icon-anchor:before {
    content: "\f13d";
}

.s-icon-unlock-alt:before {
    content: "\f13e";
}

.s-icon-bullseye:before {
    content: "\f140";
}

.s-icon-ellipsis-h:before {
    content: "\f141";
}

.s-icon-ellipsis-v:before {
    content: "\f142";
}

.s-icon-rss-square:before {
    content: "\f143";
}

.s-icon-play-circle:before {
    content: "\f144";
}

.s-icon-ticket:before {
    content: "\f145";
}

.s-icon-minus-square:before {
    content: "\f146";
}

.s-icon-minus-square-o:before {
    content: "\f147";
}

.s-icon-level-up:before {
    content: "\f148";
}

.s-icon-level-down:before {
    content: "\f149";
}

.s-icon-check-square:before {
    content: "\f14a";
}

.s-icon-pencil-square:before {
    content: "\f14b";
}

.s-icon-external-link-square:before {
    content: "\f14c";
}

.s-icon-share-square:before {
    content: "\f14d";
}

.s-icon-compass:before {
    content: "\f14e";
}

.s-icon-caret-square-o-down:before {
    content: "\f150";
}

.s-icon-toggle-down:before {
    content: "\f150";
}

.s-icon-caret-square-o-up:before {
    content: "\f151";
}

.s-icon-toggle-up:before {
    content: "\f151";
}

.s-icon-caret-square-o-right:before {
    content: "\f152";
}

.s-icon-toggle-right:before {
    content: "\f152";
}

.s-icon-eur:before {
    content: "\f153";
}

.s-icon-euro:before {
    content: "\f153";
}

.s-icon-gbp:before {
    content: "\f154";
}

.s-icon-dollar:before {
    content: "\f155";
}

.s-icon-usd:before {
    content: "\f155";
}

.s-icon-inr:before {
    content: "\f156";
}

.s-icon-rupee:before {
    content: "\f156";
}

.s-icon-cny:before {
    content: "\f157";
}

.s-icon-jpy:before {
    content: "\f157";
}

.s-icon-rmb:before {
    content: "\f157";
}

.s-icon-yen:before {
    content: "\f157";
}

.s-icon-rouble:before {
    content: "\f158";
}

.s-icon-rub:before {
    content: "\f158";
}

.s-icon-ruble:before {
    content: "\f158";
}

.s-icon-krw:before {
    content: "\f159";
}

.s-icon-won:before {
    content: "\f159";
}

.s-icon-bitcoin:before {
    content: "\f15a";
}

.s-icon-btc:before {
    content: "\f15a";
}

.s-icon-file:before {
    content: "\f15b";
}

.s-icon-file-text:before {
    content: "\f15c";
}

.s-icon-sort-alpha-asc:before {
    content: "\f15d";
}

.s-icon-sort-alpha-desc:before {
    content: "\f15e";
}

.s-icon-sort-amount-asc:before {
    content: "\f160";
}

.s-icon-sort-amount-desc:before {
    content: "\f161";
}

.s-icon-sort-numeric-asc:before {
    content: "\f162";
}

.s-icon-sort-numeric-desc:before {
    content: "\f163";
}

.s-icon-thumbs-up:before {
    content: "\f164";
}

.s-icon-thumbs-down:before {
    content: "\f165";
}

.s-icon-youtube-square:before {
    content: "\f166";
}

.s-icon-youtube:before {
    content: "\f167";
}

.s-icon-xing:before {
    content: "\f168";
}

.s-icon-xing-square:before {
    content: "\f169";
}

.s-icon-youtube-play:before {
    content: "\f16a";
}

.s-icon-dropbox:before {
    content: "\f16b";
}

.s-icon-stack-overflow:before {
    content: "\f16c";
}

.s-icon-instagram:before {
    content: "\f16d";
}

.s-icon-flickr:before {
    content: "\f16e";
}

.s-icon-adn:before {
    content: "\f170";
}

.s-icon-bitbucket:before {
    content: "\f171";
}

.s-icon-bitbucket-square:before {
    content: "\f172";
}

.s-icon-tumblr:before {
    content: "\f173";
}

.s-icon-tumblr-square:before {
    content: "\f174";
}

.s-icon-long-arrow-down:before {
    content: "\f175";
}

.s-icon-long-arrow-up:before {
    content: "\f176";
}

.s-icon-long-arrow-left:before {
    content: "\f177";
}

.s-icon-long-arrow-right:before {
    content: "\f178";
}

.Account {
    max-width: 600px;
    margin: 30px auto;
    padding: 30px;
}

.Account p {
    margin: 20px 0;
}

.s-icon-apple:before {
    content: "\f179";
    padding: 20px;
    margin-left: -30px;
}

.s-icon-windows:before {
    content: "\f17a";
}

.s-icon-android:before {
    content: "\f17b";
}

.s-icon-linux:before {
    content: "\f17c";
}

.s-icon-dribbble:before {
    content: "\f17d";
}

.s-icon-skype:before {
    content: "\f17e";
}

.s-icon-foursquare:before {
    content: "\f180";
}

.s-icon-trello:before {
    content: "\f181";
}

.s-icon-female:before {
    content: "\f182";
}

.s-icon-male:before {
    content: "\f183";
}

.s-icon-gittip:before {
    content: "\f184";
}

.s-icon-gratipay:before {
    content: "\f184";
}

.s-icon-sun-o:before {
    content: "\f185";
}

.s-icon-moon-o:before {
    content: "\f186";
}

.s-icon-archive:before {
    content: "\f187";
}

.s-icon-bug:before {
    content: "\f188";
}

.s-icon-vk:before {
    content: "\f189";
}

.s-icon-weibo:before {
    content: "\f18a";
}

.s-icon-renren:before {
    content: "\f18b";
}

.s-icon-pagelines:before {
    content: "\f18c";
}

.s-icon-stack-exchange:before {
    content: "\f18d";
}

.s-icon-arrow-circle-o-right:before {
    content: "\f18e";
}

.s-icon-arrow-circle-o-left:before {
    content: "\f190";
}

.s-icon-caret-square-o-left:before {
    content: "\f191";
}

.s-icon-toggle-left:before {
    content: "\f191";
}

.s-icon-dot-circle-o:before {
    content: "\f192";
}

.s-icon-wheelchair:before {
    content: "\f193";
}

.s-icon-vimeo-square:before {
    content: "\f194";
}

.s-icon-try:before {
    content: "\f195";
}

.s-icon-turkish-lira:before {
    content: "\f195";
}

.s-icon-plus-square-o:before {
    content: "\f196";
}

.s-icon-space-shuttle:before {
    content: "\f197";
}

.s-icon-slack:before {
    content: "\f198";
}

.s-icon-envelope-square:before {
    content: "\f199";
}

.s-icon-wordpress:before {
    content: "\f19a";
}

.s-icon-openid:before {
    content: "\f19b";
}

.s-icon-bank:before {
    content: "\f19c";
}

.s-icon-institution:before {
    content: "\f19c";
}

.s-icon-university:before {
    content: "\f19c";
}

.s-icon-graduation-cap:before {
    content: "\f19d";
}

.s-icon-mortar-board:before {
    content: "\f19d";
}

.s-icon-yahoo:before {
    content: "\f19e";
}

.s-icon-google:before {
    content: "\f1a0";
    padding: 20px;
    margin-left: -30px;
}

.s-icon-reddit:before {
    content: "\f1a1";
}

.s-icon-reddit-square:before {
    content: "\f1a2";
}

.s-icon-stumbleupon-circle:before {
    content: "\f1a3";
}

.s-icon-stumbleupon:before {
    content: "\f1a4";
}

.s-icon-delicious:before {
    content: "\f1a5";
}

.s-icon-digg:before {
    content: "\f1a6";
}

.s-icon-pied-piper-pp:before {
    content: "\f1a7";
}

.s-icon-pied-piper-alt:before {
    content: "\f1a8";
}

.s-icon-drupal:before {
    content: "\f1a9";
}

.s-icon-joomla:before {
    content: "\f1aa";
}

.s-icon-language:before {
    content: "\f1ab";
}

.s-icon-fax:before {
    content: "\f1ac";
}

.s-icon-building:before {
    content: "\f1ad";
}

.s-icon-child:before {
    content: "\f1ae";
}

.s-icon-paw:before {
    content: "\f1b0";
}

.s-icon-spoon:before {
    content: "\f1b1";
}

.s-icon-cube:before {
    content: "\f1b2";
}

.s-icon-cubes:before {
    content: "\f1b3";
}

.s-icon-behance:before {
    content: "\f1b4";
}

.s-icon-behance-square:before {
    content: "\f1b5";
}

.s-icon-steam:before {
    content: "\f1b6";
}

.s-icon-steam-square:before {
    content: "\f1b7";
}

.s-icon-recycle:before {
    content: "\f1b8";
}

.s-icon-automobile:before {
    content: "\f1b9";
}

.s-icon-car:before {
    content: "\f1b9";
}

.s-icon-cab:before {
    content: "\f1ba";
}

.s-icon-taxi:before {
    content: "\f1ba";
}

.s-icon-tree:before {
    content: "\f1bb";
}

.s-icon-spotify:before {
    content: "\f1bc";
}

.s-icon-deviantart:before {
    content: "\f1bd";
}

.s-icon-soundcloud:before {
    content: "\f1be";
}

.s-icon-database:before {
    content: "\f1c0";
}

.s-icon-file-pdf-o:before {
    content: "\f1c1";
}

.s-icon-file-word-o:before {
    content: "\f1c2";
}

.s-icon-file-excel-o:before {
    content: "\f1c3";
}

.s-icon-file-powerpoint-o:before {
    content: "\f1c4";
}

.s-icon-file-image-o:before {
    content: "\f1c5";
}

.s-icon-file-photo-o:before {
    content: "\f1c5";
}

.s-icon-file-picture-o:before {
    content: "\f1c5";
}

.s-icon-file-archive-o:before {
    content: "\f1c6";
}

.s-icon-file-zip-o:before {
    content: "\f1c6";
}

.s-icon-file-audio-o:before {
    content: "\f1c7";
}

.s-icon-file-sound-o:before {
    content: "\f1c7";
}

.s-icon-file-movie-o:before {
    content: "\f1c8";
}

.s-icon-file-video-o:before {
    content: "\f1c8";
}

.s-icon-file-code-o:before {
    content: "\f1c9";
}

.s-icon-vine:before {
    content: "\f1ca";
}

.s-icon-codepen:before {
    content: "\f1cb";
}

.s-icon-jsfiddle:before {
    content: "\f1cc";
}

.s-icon-life-bouy:before {
    content: "\f1cd";
}

.s-icon-life-buoy:before {
    content: "\f1cd";
}

.s-icon-life-ring:before {
    content: "\f1cd";
}

.s-icon-life-saver:before {
    content: "\f1cd";
}

.s-icon-support:before {
    content: "\f1cd";
}

.s-icon-circle-o-notch:before {
    content: "\f1ce";
}

.s-icon-ra:before {
    content: "\f1d0";
}

.s-icon-rebel:before {
    content: "\f1d0";
}

.s-icon-resistance:before {
    content: "\f1d0";
}

.s-icon-empire:before {
    content: "\f1d1";
}

.s-icon-ge:before {
    content: "\f1d1";
}

.s-icon-git-square:before {
    content: "\f1d2";
}

.s-icon-git:before {
    content: "\f1d3";
}

.s-icon-hacker-news:before {
    content: "\f1d4";
}

.s-icon-y-combinator-square:before {
    content: "\f1d4";
}

.s-icon-yc-square:before {
    content: "\f1d4";
}

.s-icon-tencent-weibo:before {
    content: "\f1d5";
}

.s-icon-qq:before {
    content: "\f1d6";
}

.s-icon-wechat:before {
    content: "\f1d7";
}

.s-icon-weixin:before {
    content: "\f1d7";
}

.s-icon-paper-plane:before {
    content: "\f1d8";
}

.s-icon-send:before {
    content: "\f1d8";
}

.s-icon-paper-plane-o:before {
    content: "\f1d9";
}

.s-icon-send-o:before {
    content: "\f1d9";
}

.s-icon-history:before {
    content: "\f1da";
}

.s-icon-circle-thin:before {
    content: "\f1db";
}

.s-icon-header:before {
    content: "\f1dc";
}

.s-icon-paragraph:before {
    content: "\f1dd";
}

.s-icon-sliders:before {
    content: "\f1de";
}

.s-icon-share-alt:before {
    content: "\f1e0";
}

.s-icon-share-alt-square:before {
    content: "\f1e1";
}

.s-icon-bomb:before {
    content: "\f1e2";
}

.s-icon-futbol-o:before {
    content: "\f1e3";
}

.s-icon-soccer-ball-o:before {
    content: "\f1e3";
}

.s-icon-tty:before {
    content: "\f1e4";
}

.s-icon-binoculars:before {
    content: "\f1e5";
}

.s-icon-plug:before {
    content: "\f1e6";
}

.s-icon-slideshare:before {
    content: "\f1e7";
}

.s-icon-twitch:before {
    content: "\f1e8";
}

.s-icon-yelp:before {
    content: "\f1e9";
}

.s-icon-newspaper-o:before {
    content: "\f1ea";
}

.s-icon-wifi:before {
    content: "\f1eb";
}

.s-icon-calculator:before {
    content: "\f1ec";
}

.s-icon-paypal:before {
    content: "\f1ed";
}

.s-icon-google-wallet:before {
    content: "\f1ee";
}

.s-icon-cc-visa:before {
    content: "\f1f0";
}

.s-icon-cc-mastercard:before {
    content: "\f1f1";
}

.s-icon-cc-discover:before {
    content: "\f1f2";
}

.s-icon-cc-amex:before {
    content: "\f1f3";
}

.s-icon-cc-paypal:before {
    content: "\f1f4";
}

.s-icon-cc-stripe:before {
    content: "\f1f5";
}

.s-icon-bell-slash:before {
    content: "\f1f6";
}

.s-icon-bell-slash-o:before {
    content: "\f1f7";
}

.s-icon-trash:before {
    content: "\f1f8";
}

.s-icon-copyright:before {
    content: "\f1f9";
}

.s-icon-at:before {
    content: "\f1fa";
}

.s-icon-eyedropper:before {
    content: "\f1fb";
}

.s-icon-paint-brush:before {
    content: "\f1fc";
}

.s-icon-birthday-cake:before {
    content: "\f1fd";
}

.s-icon-area-chart:before {
    content: "\f1fe";
}

.s-icon-pie-chart:before {
    content: "\f200";
}

.s-icon-line-chart:before {
    content: "\f201";
}

.s-icon-lastfm:before {
    content: "\f202";
}

.s-icon-lastfm-square:before {
    content: "\f203";
}

.s-icon-toggle-off:before {
    content: "\f204";
}

.s-icon-toggle-on:before {
    content: "\f205";
}

.s-icon-bicycle:before {
    content: "\f206";
}

.s-icon-bus:before {
    content: "\f207";
}

.s-icon-ioxhost:before {
    content: "\f208";
}

.s-icon-angellist:before {
    content: "\f209";
}

.s-icon-cc:before {
    content: "\f20a";
}

.s-icon-ils:before {
    content: "\f20b";
}

.s-icon-shekel:before {
    content: "\f20b";
}

.s-icon-sheqel:before {
    content: "\f20b";
}

.s-icon-meanpath:before {
    content: "\f20c";
}

.s-icon-buysellads:before {
    content: "\f20d";
}

.s-icon-connectdevelop:before {
    content: "\f20e";
}

.s-icon-dashcube:before {
    content: "\f210";
}

.s-icon-forumbee:before {
    content: "\f211";
}

.s-icon-leanpub:before {
    content: "\f212";
}

.s-icon-sellsy:before {
    content: "\f213";
}

.s-icon-shirtsinbulk:before {
    content: "\f214";
}

.s-icon-simplybuilt:before {
    content: "\f215";
}

.s-icon-skyatlas:before {
    content: "\f216";
}

.s-icon-cart-plus:before {
    content: "\f217";
}

.s-icon-cart-arrow-down:before {
    content: "\f218";
}

.s-icon-diamond:before {
    content: "\f219";
}

.s-icon-ship:before {
    content: "\f21a";
}

.s-icon-user-secret:before {
    content: "\f21b";
}

.s-icon-motorcycle:before {
    content: "\f21c";
}

.s-icon-street-view:before {
    content: "\f21d";
}

.s-icon-heartbeat:before {
    content: "\f21e";
}

.s-icon-venus:before {
    content: "\f221";
}

.s-icon-mars:before {
    content: "\f222";
}

.s-icon-mercury:before {
    content: "\f223";
}

.s-icon-intersex:before {
    content: "\f224";
}

.s-icon-transgender:before {
    content: "\f224";
}

.s-icon-transgender-alt:before {
    content: "\f225";
}

.s-icon-venus-double:before {
    content: "\f226";
}

.s-icon-mars-double:before {
    content: "\f227";
}

.s-icon-venus-mars:before {
    content: "\f228";
}

.s-icon-mars-stroke:before {
    content: "\f229";
}

.s-icon-mars-stroke-v:before {
    content: "\f22a";
}

.s-icon-mars-stroke-h:before {
    content: "\f22b";
}

.s-icon-neuter:before {
    content: "\f22c";
}

.s-icon-genderless:before {
    content: "\f22d";
}

.s-icon-facebook-official:before {
    content: "\f230";
}

.s-icon-pinterest-p:before {
    content: "\f231";
}

.s-icon-whatsapp:before {
    content: "\f232";
}

.s-icon-server:before {
    content: "\f233";
}

.s-icon-user-plus:before {
    content: "\f234";
}

.s-icon-user-times:before {
    content: "\f235";
}

.s-icon-bed:before {
    content: "\f236";
}

.s-icon-hotel:before {
    content: "\f236";
}

.s-icon-viacoin:before {
    content: "\f237";
}

.s-icon-train:before {
    content: "\f238";
}

.s-icon-subway:before {
    content: "\f239";
}

.s-icon-medium:before {
    content: "\f23a";
}

.s-icon-y-combinator:before {
    content: "\f23b";
}

.s-icon-yc:before {
    content: "\f23b";
}

.s-icon-optin-monster:before {
    content: "\f23c";
}

.s-icon-opencart:before {
    content: "\f23d";
}

.s-icon-expeditedssl:before {
    content: "\f23e";
}

.s-icon-battery:before {
    content: "\f240";
}

.s-icon-battery-4:before {
    content: "\f240";
}

.s-icon-battery-full:before {
    content: "\f240";
}

.s-icon-battery-3:before {
    content: "\f241";
}

.s-icon-battery-three-quarters:before {
    content: "\f241";
}

.s-icon-battery-2:before {
    content: "\f242";
}

.s-icon-battery-half:before {
    content: "\f242";
}

.s-icon-battery-1:before {
    content: "\f243";
}

.s-icon-battery-quarter:before {
    content: "\f243";
}

.s-icon-battery-0:before {
    content: "\f244";
}

.s-icon-battery-empty:before {
    content: "\f244";
}

.s-icon-mouse-pointer:before {
    content: "\f245";
}

.s-icon-i-cursor:before {
    content: "\f246";
}

.s-icon-object-group:before {
    content: "\f247";
}

.s-icon-object-ungroup:before {
    content: "\f248";
}

.s-icon-sticky-note:before {
    content: "\f249";
}

.s-icon-sticky-note-o:before {
    content: "\f24a";
}

.s-icon-cc-jcb:before {
    content: "\f24b";
}

.s-icon-cc-diners-club:before {
    content: "\f24c";
}

.s-icon-clone:before {
    content: "\f24d";
}

.s-icon-balance-scale:before {
    content: "\f24e";
}

.s-icon-hourglass-o:before {
    content: "\f250";
}

.s-icon-hourglass-1:before {
    content: "\f251";
}

.s-icon-hourglass-start:before {
    content: "\f251";
}

.s-icon-hourglass-2:before {
    content: "\f252";
}

.s-icon-hourglass-half:before {
    content: "\f252";
}

.s-icon-hourglass-3:before {
    content: "\f253";
}

.s-icon-hourglass-end:before {
    content: "\f253";
}

.s-icon-hourglass:before {
    content: "\f254";
}

.s-icon-hand-grab-o:before {
    content: "\f255";
}

.s-icon-hand-rock-o:before {
    content: "\f255";
}

.s-icon-hand-paper-o:before {
    content: "\f256";
}

.s-icon-hand-stop-o:before {
    content: "\f256";
}

.s-icon-hand-scissors-o:before {
    content: "\f257";
}

.s-icon-hand-lizard-o:before {
    content: "\f258";
}

.s-icon-hand-spock-o:before {
    content: "\f259";
}

.s-icon-hand-pointer-o:before {
    content: "\f25a";
}

.s-icon-hand-peace-o:before {
    content: "\f25b";
}

.s-icon-trademark:before {
    content: "\f25c";
}

.s-icon-registered:before {
    content: "\f25d";
}

.s-icon-creative-commons:before {
    content: "\f25e";
}

.s-icon-gg:before {
    content: "\f260";
}

.s-icon-gg-circle:before {
    content: "\f261";
}

.s-icon-tripadvisor:before {
    content: "\f262";
}

.s-icon-odnoklassniki:before {
    content: "\f263";
}

.s-icon-odnoklassniki-square:before {
    content: "\f264";
}

.s-icon-get-pocket:before {
    content: "\f265";
}

.s-icon-wikipedia-w:before {
    content: "\f266";
}

.s-icon-safari:before {
    content: "\f267";
}

.s-icon-chrome:before {
    content: "\f268";
}

.s-icon-firefox:before {
    content: "\f269";
}

.s-icon-opera:before {
    content: "\f26a";
}

.s-icon-internet-explorer:before {
    content: "\f26b";
}

.s-icon-television:before {
    content: "\f26c";
}

.s-icon-tv:before {
    content: "\f26c";
}

.s-icon-contao:before {
    content: "\f26d";
}

.s-icon-500px:before {
    content: "\f26e";
}

.s-icon-amazon:before {
    content: "\f270";
}

.s-icon-calendar-plus-o:before {
    content: "\f271";
}

.s-icon-calendar-minus-o:before {
    content: "\f272";
}

.s-icon-calendar-times-o:before {
    content: "\f273";
}

.s-icon-calendar-check-o:before {
    content: "\f274";
}

.s-icon-industry:before {
    content: "\f275";
}

.s-icon-map-pin:before {
    content: "\f276";
}

.s-icon-map-signs:before {
    content: "\f277";
}

.s-icon-map-o:before {
    content: "\f278";
}

.s-icon-map:before {
    content: "\f279";
}

.s-icon-commenting:before {
    content: "\f27a";
}

.s-icon-commenting-o:before {
    content: "\f27b";
}

.s-icon-houzz:before {
    content: "\f27c";
}

.s-icon-vimeo:before {
    content: "\f27d";
}

.s-icon-black-tie:before {
    content: "\f27e";
}

.s-icon-fonticons:before {
    content: "\f280";
}

.s-icon-reddit-alien:before {
    content: "\f281";
}

.s-icon-edge:before {
    content: "\f282";
}

.s-icon-credit-card-alt:before {
    content: "\f283";
}

.s-icon-codiepie:before {
    content: "\f284";
}

.s-icon-modx:before {
    content: "\f285";
}

.s-icon-fort-awesome:before {
    content: "\f286";
}

.s-icon-usb:before {
    content: "\f287";
}

.s-icon-product-hunt:before {
    content: "\f288";
}

.s-icon-mixcloud:before {
    content: "\f289";
}

.s-icon-scribd:before {
    content: "\f28a";
}

.s-icon-pause-circle:before {
    content: "\f28b";
}

.s-icon-pause-circle-o:before {
    content: "\f28c";
}

.s-icon-stop-circle:before {
    content: "\f28d";
}

.s-icon-stop-circle-o:before {
    content: "\f28e";
}

.s-icon-shopping-bag:before {
    content: "\f290";
}

.s-icon-shopping-basket:before {
    content: "\f291";
}

.s-icon-hashtag:before {
    content: "\f292";
}

.s-icon-bluetooth:before {
    content: "\f293";
}

.s-icon-bluetooth-b:before {
    content: "\f294";
}

.s-icon-percent:before {
    content: "\f295";
}

.s-icon-gitlab:before {
    content: "\f296";
}

.s-icon-wpbeginner:before {
    content: "\f297";
}

.s-icon-wpforms:before {
    content: "\f298";
}

.s-icon-envira:before {
    content: "\f299";
}

.s-icon-universal-access:before {
    content: "\f29a";
}

.s-icon-wheelchair-alt:before {
    content: "\f29b";
}

.s-icon-question-circle-o:before {
    content: "\f29c";
}

.s-icon-blind:before {
    content: "\f29d";
}

.s-icon-audio-description:before {
    content: "\f29e";
}

.s-icon-volume-control-phone:before {
    content: "\f2a0";
}

.s-icon-braille:before {
    content: "\f2a1";
}

.s-icon-assistive-listening-systems:before {
    content: "\f2a2";
}

.s-icon-american-sign-language-interpreting:before {
    content: "\f2a3";
}

.s-icon-asl-interpreting:before {
    content: "\f2a3";
}

.s-icon-deaf:before {
    content: "\f2a4";
}

.s-icon-deafness:before {
    content: "\f2a4";
}

.s-icon-hard-of-hearing:before {
    content: "\f2a4";
}

.s-icon-glide:before {
    content: "\f2a5";
}

.s-icon-glide-g:before {
    content: "\f2a6";
}

.s-icon-sign-language:before {
    content: "\f2a7";
}

.s-icon-signing:before {
    content: "\f2a7";
}

.s-icon-low-vision:before {
    content: "\f2a8";
}

.s-icon-viadeo:before {
    content: "\f2a9";
}

.s-icon-viadeo-square:before {
    content: "\f2aa";
}

.s-icon-snapchat:before {
    content: "\f2ab";
}

.s-icon-snapchat-ghost:before {
    content: "\f2ac";
}

.s-icon-snapchat-square:before {
    content: "\f2ad";
}

.s-icon-pied-piper:before {
    content: "\f2ae";
}

.s-icon-first-order:before {
    content: "\f2b0";
}

.s-icon-yoast:before {
    content: "\f2b1";
}

.s-icon-themeisle:before {
    content: "\f2b2";
}

.s-icon-google-plus-circle:before {
    content: "\f2b3";
}

.s-icon-google-plus-official:before {
    content: "\f2b3";
}

.s-icon-fa:before {
    content: "\f2b4";
}

.s-icon-font-awesome:before {
    content: "\f2b4";
}

.s-icon-handshake-o:before {
    content: "\f2b5";
}

.s-icon-envelope-open:before {
    content: "\f2b6";
}

.s-icon-envelope-open-o:before {
    content: "\f2b7";
}

.s-icon-linode:before {
    content: "\f2b8";
}

.s-icon-address-book:before {
    content: "\f2b9";
}

.s-icon-address-book-o:before {
    content: "\f2ba";
}

.s-icon-address-card:before {
    content: "\f2bb";
}

.s-icon-vcard:before {
    content: "\f2bb";
}

.s-icon-address-card-o:before {
    content: "\f2bc";
}

.s-icon-vcard-o:before {
    content: "\f2bc";
}

.s-icon-user-circle:before {
    content: "\f2bd";
}

.s-icon-user-circle-o:before {
    content: "\f2be";
}

.s-icon-user-o:before {
    content: "\f2c0";
}

.s-icon-id-badge:before {
    content: "\f2c1";
}

.s-icon-drivers-license:before {
    content: "\f2c2";
}

.s-icon-id-card:before {
    content: "\f2c2";
}

.s-icon-drivers-license-o:before {
    content: "\f2c3";
}

.s-icon-id-card-o:before {
    content: "\f2c3";
}

.s-icon-quora:before {
    content: "\f2c4";
}

.s-icon-free-code-camp:before {
    content: "\f2c5";
}

.s-icon-telegram:before {
    content: "\f2c6";
}

.s-icon-thermometer:before {
    content: "\f2c7";
}

.s-icon-thermometer-4:before {
    content: "\f2c7";
}

.s-icon-thermometer-full:before {
    content: "\f2c7";
}

.s-icon-thermometer-3:before {
    content: "\f2c8";
}

.s-icon-thermometer-three-quarters:before {
    content: "\f2c8";
}

.s-icon-thermometer-2:before {
    content: "\f2c9";
}

.s-icon-thermometer-half:before {
    content: "\f2c9";
}

.s-icon-thermometer-1:before {
    content: "\f2ca";
}

.s-icon-thermometer-quarter:before {
    content: "\f2ca";
}

.s-icon-thermometer-0:before {
    content: "\f2cb";
}

.s-icon-thermometer-empty:before {
    content: "\f2cb";
}

.s-icon-shower:before {
    content: "\f2cc";
}

.s-icon-bath:before {
    content: "\f2cd";
}

.s-icon-bathtub:before {
    content: "\f2cd";
}

.s-icon-s15:before {
    content: "\f2cd";
}

.s-icon-podcast:before {
    content: "\f2ce";
}

.s-icon-window-maximize:before {
    content: "\f2d0";
}

.s-icon-window-minimize:before {
    content: "\f2d1";
}

.s-icon-window-restore:before {
    content: "\f2d2";
}

.s-icon-times-rectangle:before {
    content: "\f2d3";
}

.s-icon-window-close:before {
    content: "\f2d3";
}

.s-icon-times-rectangle-o:before {
    content: "\f2d4";
}

.s-icon-window-close-o:before {
    content: "\f2d4";
}

.s-icon-bandcamp:before {
    content: "\f2d5";
}

.s-icon-grav:before {
    content: "\f2d6";
}

.s-icon-etsy:before {
    content: "\f2d7";
}

.s-icon-imdb:before {
    content: "\f2d8";
}

.s-icon-ravelry:before {
    content: "\f2d9";
}

.s-icon-eercast:before {
    content: "\f2da";
}

.s-icon-microchip:before {
    content: "\f2db";
}

.s-icon-snowflake-o:before {
    content: "\f2dc";
}

.s-icon-superpowers:before {
    content: "\f2dd";
}

.s-icon-wpexplorer:before {
    content: "\f2de";
}

.s-icon-meetup:before {
    content: "\f2e0";
}

.s-icon-3d_rotation:before {
    content: "\e84d";
}

.s-icon-ac_unit:before {
    content: "\eb3b";
}

.s-icon-alarm:before {
    content: "\e855";
}

.s-icon-access_alarms:before {
    content: "\e191";
}

.s-icon-schedule:before {
    content: "\e8b5";
}

.s-icon-accessibility:before {
    content: "\e84e";
}

.s-icon-accessible:before {
    content: "\e914";
}

.s-icon-account_balance:before {
    content: "\e84f";
}

.s-icon-account_balance_wallet:before {
    content: "\e850";
}

.s-icon-account_box:before {
    content: "\e851";
}

.s-icon-account_circle:before {
    content: "\e853";
}

.s-icon-adb:before {
    content: "\e60e";
}

.s-icon-add:before {
    content: "\e145";
}

.s-icon-add_a_photo:before {
    content: "\e439";
}

.s-icon-alarm_add:before {
    content: "\e856";
}

.s-icon-add_alert:before {
    content: "\e003";
}

.s-icon-add_box:before {
    content: "\e146";
}

.s-icon-add_circle:before {
    content: "\e147";
}

.s-icon-control_point:before {
    content: "\e3ba";
}

.s-icon-add_location:before {
    content: "\e567";
}

.s-icon-add_shopping_cart:before {
    content: "\e854";
}

.s-icon-queue:before {
    content: "\e03c";
}

.s-icon-add_to_queue:before {
    content: "\e05c";
}

.s-icon-adjust2:before {
    content: "\e39e";
}

.s-icon-airline_seat_flat:before {
    content: "\e630";
}

.s-icon-airline_seat_flat_angled:before {
    content: "\e631";
}

.s-icon-airline_seat_individual_suite:before {
    content: "\e632";
}

.s-icon-airline_seat_legroom_extra:before {
    content: "\e633";
}

.s-icon-airline_seat_legroom_normal:before {
    content: "\e634";
}

.s-icon-airline_seat_legroom_reduced:before {
    content: "\e635";
}

.s-icon-airline_seat_recline_extra:before {
    content: "\e636";
}

.s-icon-airline_seat_recline_normal:before {
    content: "\e637";
}

.s-icon-flight:before {
    content: "\e539";
}

.s-icon-airplanemode_inactive:before {
    content: "\e194";
}

.s-icon-airplay:before {
    content: "\e055";
}

.s-icon-airport_shuttle:before {
    content: "\eb3c";
}

.s-icon-alarm_off:before {
    content: "\e857";
}

.s-icon-alarm_on:before {
    content: "\e858";
}

.s-icon-album:before {
    content: "\e019";
}

.s-icon-all_inclusive:before {
    content: "\eb3d";
}

.s-icon-all_out:before {
    content: "\e90b";
}

.s-icon-android2:before {
    content: "\e859";
}

.s-icon-announcement:before {
    content: "\e85a";
}

.s-icon-apps:before {
    content: "\e5c3";
}

.s-icon-archive2:before {
    content: "\e149";
}

.s-icon-arrow_back:before {
    content: "\e5c4";
}

.s-icon-arrow_downward:before {
    content: "\e5db";
}

.s-icon-arrow_drop_down:before {
    content: "\e5c5";
}

.s-icon-arrow_drop_down_circle:before {
    content: "\e5c6";
}

.s-icon-arrow_drop_up:before {
    content: "\e5c7";
}

.s-icon-arrow_forward:before {
    content: "\e5c8";
}

.s-icon-arrow_upward:before {
    content: "\e5d8";
}

.s-icon-art_track:before {
    content: "\e060";
}

.s-icon-aspect_ratio:before {
    content: "\e85b";
}

.s-icon-poll:before {
    content: "\e801";
}

.s-icon-assignment:before {
    content: "\e85d";
}

.s-icon-assignment_ind:before {
    content: "\e85e";
}

.s-icon-assignment_late:before {
    content: "\e85f";
}

.s-icon-assignment_return:before {
    content: "\e860";
}

.s-icon-assignment_returned:before {
    content: "\e861";
}

.s-icon-assignment_turned_in:before {
    content: "\e862";
}

.s-icon-assistant:before {
    content: "\e39f";
}

.s-icon-flag2:before {
    content: "\e153";
}

.s-icon-attach_file:before {
    content: "\e226";
}

.s-icon-attach_money:before {
    content: "\e227";
}

.s-icon-attachment:before {
    content: "\e2bc";
}

.s-icon-audiotrack:before {
    content: "\e3a1";
}

.s-icon-autorenew:before {
    content: "\e863";
}

.s-icon-av_timer:before {
    content: "\e01b";
}

.s-icon-backspace:before {
    content: "\e14a";
}

.s-icon-cloud_upload:before {
    content: "\e2c3";
}

.s-icon-battery_alert:before {
    content: "\e19c";
}

.s-icon-battery_charging_full:before {
    content: "\e1a3";
}

.s-icon-battery_std:before {
    content: "\e1a5";
}

.s-icon-battery_unknown:before {
    content: "\e1a6";
}

.s-icon-beach_access:before {
    content: "\eb3e";
}

.s-icon-beenhere:before {
    content: "\e52d";
}

.s-icon-block:before {
    content: "\e14b";
}

.s-icon-bluetooth2:before {
    content: "\e1a7";
}

.s-icon-bluetooth_searching:before {
    content: "\e1aa";
}

.s-icon-bluetooth_connected:before {
    content: "\e1a8";
}

.s-icon-bluetooth_disabled:before {
    content: "\e1a9";
}

.s-icon-blur_circular:before {
    content: "\e3a2";
}

.s-icon-blur_linear:before {
    content: "\e3a3";
}

.s-icon-blur_off:before {
    content: "\e3a4";
}

.s-icon-blur_on:before {
    content: "\e3a5";
}

.s-icon-class:before {
    content: "\e86e";
}

.s-icon-turned_in:before {
    content: "\e8e6";
}

.s-icon-turned_in_not:before {
    content: "\e8e7";
}

.s-icon-border_all:before {
    content: "\e228";
}

.s-icon-border_bottom:before {
    content: "\e229";
}

.s-icon-border_clear:before {
    content: "\e22a";
}

.s-icon-border_color:before {
    content: "\e22b";
}

.s-icon-border_horizontal:before {
    content: "\e22c";
}

.s-icon-border_inner:before {
    content: "\e22d";
}

.s-icon-border_left:before {
    content: "\e22e";
}

.s-icon-border_outer:before {
    content: "\e22f";
}

.s-icon-border_right:before {
    content: "\e230";
}

.s-icon-border_style:before {
    content: "\e231";
}

.s-icon-border_top:before {
    content: "\e232";
}

.s-icon-border_vertical:before {
    content: "\e233";
}

.s-icon-branding_watermark:before {
    content: "\e06b";
}

.s-icon-brightness_1:before {
    content: "\e3a6";
}

.s-icon-brightness_2:before {
    content: "\e3a7";
}

.s-icon-brightness_3:before {
    content: "\e3a8";
}

.s-icon-brightness_4:before {
    content: "\e3a9";
}

.s-icon-brightness_low:before {
    content: "\e1ad";
}

.s-icon-brightness_medium:before {
    content: "\e1ae";
}

.s-icon-brightness_high:before {
    content: "\e1ac";
}

.s-icon-brightness_auto:before {
    content: "\e1ab";
}

.s-icon-broken_image:before {
    content: "\e3ad";
}

.s-icon-brush:before {
    content: "\e3ae";
}

.s-icon-bubble_chart:before {
    content: "\e6dd";
}

.s-icon-bug_report:before {
    content: "\e868";
}

.s-icon-build:before {
    content: "\e869";
}

.s-icon-burst_mode:before {
    content: "\e43c";
}

.s-icon-domain:before {
    content: "\e7ee";
}

.s-icon-business_center:before {
    content: "\eb3f";
}

.s-icon-cached:before {
    content: "\e86a";
}

.s-icon-cake:before {
    content: "\e7e9";
}

.s-icon-phone2:before {
    content: "\e0cd";
}

.s-icon-call_end:before {
    content: "\e0b1";
}

.s-icon-call_made:before {
    content: "\e0b2";
}

.s-icon-merge_type:before {
    content: "\e252";
}

.s-icon-call_missed:before {
    content: "\e0b4";
}

.s-icon-call_missed_outgoing:before {
    content: "\e0e4";
}

.s-icon-call_received:before {
    content: "\e0b5";
}

.s-icon-call_split:before {
    content: "\e0b6";
}

.s-icon-call_to_action:before {
    content: "\e06c";
}

.s-icon-camera2:before {
    content: "\e3af";
}

.s-icon-photo_camera:before {
    content: "\e412";
}

.s-icon-camera_enhance:before {
    content: "\e8fc";
}

.s-icon-camera_front:before {
    content: "\e3b1";
}

.s-icon-camera_rear:before {
    content: "\e3b2";
}

.s-icon-camera_roll:before {
    content: "\e3b3";
}

.s-icon-cancel:before {
    content: "\e5c9";
}

.s-icon-redeem:before {
    content: "\e8b1";
}

.s-icon-card_membership:before {
    content: "\e8f7";
}

.s-icon-card_travel:before {
    content: "\e8f8";
}

.s-icon-casino:before {
    content: "\eb40";
}

.s-icon-cast:before {
    content: "\e307";
}

.s-icon-cast_connected:before {
    content: "\e308";
}

.s-icon-center_focus_strong:before {
    content: "\e3b4";
}

.s-icon-center_focus_weak:before {
    content: "\e3b5";
}

.s-icon-change_history:before {
    content: "\e86b";
}

.s-icon-chat:before {
    content: "\e0b7";
}

.s-icon-chat_bubble:before {
    content: "\e0ca";
}

.s-icon-chat_bubble_outline:before {
    content: "\e0cb";
}

.s-icon-check2:before {
    content: "\e5ca";
}

.s-icon-check_box:before {
    content: "\e834";
}

.s-icon-check_box_outline_blank:before {
    content: "\e835";
}

.s-icon-check_circle:before {
    content: "\e86c";
}

.s-icon-navigate_before:before {
    content: "\e408";
}

.s-icon-navigate_next:before {
    content: "\e409";
}

.s-icon-child_care:before {
    content: "\eb41";
}

.s-icon-child_friendly:before {
    content: "\eb42";
}

.s-icon-chrome_reader_mode:before {
    content: "\e86d";
}

.s-icon-close2:before {
    content: "\e5cd";
}

.s-icon-clear_all:before {
    content: "\e0b8";
}

.s-icon-closed_caption:before {
    content: "\e01c";
}

.s-icon-wb_cloudy:before {
    content: "\e42d";
}

.s-icon-cloud_circle:before {
    content: "\e2be";
}

.s-icon-cloud_done:before {
    content: "\e2bf";
}

.s-icon-cloud_download:before {
    content: "\e2c0";
}

.s-icon-cloud_off:before {
    content: "\e2c1";
}

.s-icon-cloud_queue:before {
    content: "\e2c2";
}

.s-icon-code2:before {
    content: "\e86f";
}

.s-icon-photo_library:before {
    content: "\e413";
}

.s-icon-collections_bookmark:before {
    content: "\e431";
}

.s-icon-palette:before {
    content: "\e40a";
}

.s-icon-colorize:before {
    content: "\e3b8";
}

.s-icon-comment2:before {
    content: "\e0b9";
}

.s-icon-compare:before {
    content: "\e3b9";
}

.s-icon-compare_arrows:before {
    content: "\e915";
}

.s-icon-laptop2:before {
    content: "\e31e";
}

.s-icon-confirmation_number:before {
    content: "\e638";
}

.s-icon-contact_mail:before {
    content: "\e0d0";
}

.s-icon-contact_phone:before {
    content: "\e0cf";
}

.s-icon-contacts:before {
    content: "\e0ba";
}

.s-icon-content_copy:before {
    content: "\e14d";
}

.s-icon-content_cut:before {
    content: "\e14e";
}

.s-icon-content_paste:before {
    content: "\e14f";
}

.s-icon-control_point_duplicate:before {
    content: "\e3bb";
}

.s-icon-copyright2:before {
    content: "\e90c";
}

.s-icon-mode_edit:before {
    content: "\e254";
}

.s-icon-create_new_folder:before {
    content: "\e2cc";
}

.s-icon-payment:before {
    content: "\e8a1";
}

.s-icon-crop2:before {
    content: "\e3be";
}

.s-icon-crop_16_9:before {
    content: "\e3bc";
}

.s-icon-crop_3_2:before {
    content: "\e3bd";
}

.s-icon-crop_landscape:before {
    content: "\e3c3";
}

.s-icon-crop_7_5:before {
    content: "\e3c0";
}

.s-icon-crop_din:before {
    content: "\e3c1";
}

.s-icon-crop_free:before {
    content: "\e3c2";
}

.s-icon-crop_original:before {
    content: "\e3c4";
}

.s-icon-crop_portrait:before {
    content: "\e3c5";
}

.s-icon-crop_rotate:before {
    content: "\e437";
}

.s-icon-crop_square:before {
    content: "\e3c6";
}

.s-icon-dashboard2:before {
    content: "\e871";
}

.s-icon-data_usage:before {
    content: "\e1af";
}

.s-icon-date_range:before {
    content: "\e916";
}

.s-icon-dehaze:before {
    content: "\e3c7";
}

.s-icon-delete:before {
    content: "\e872";
}

.s-icon-delete_forever:before {
    content: "\e92b";
}

.s-icon-delete_sweep:before {
    content: "\e16c";
}

.s-icon-description:before {
    content: "\e873";
}

.s-icon-desktop_mac:before {
    content: "\e30b";
}

.s-icon-desktop_windows:before {
    content: "\e30c";
}

.s-icon-details:before {
    content: "\e3c8";
}

.s-icon-developer_board:before {
    content: "\e30d";
}

.s-icon-developer_mode:before {
    content: "\e1b0";
}

.s-icon-device_hub:before {
    content: "\e335";
}

.s-icon-phonelink:before {
    content: "\e326";
}

.s-icon-devices_other:before {
    content: "\e337";
}

.s-icon-dialer_sip:before {
    content: "\e0bb";
}

.s-icon-dialpad:before {
    content: "\e0bc";
}

.s-icon-directions:before {
    content: "\e52e";
}

.s-icon-directions_bike:before {
    content: "\e52f";
}

.s-icon-directions_boat:before {
    content: "\e532";
}

.s-icon-directions_bus:before {
    content: "\e530";
}

.s-icon-directions_car:before {
    content: "\e531";
}

.s-icon-directions_railway:before {
    content: "\e534";
}

.s-icon-directions_run:before {
    content: "\e566";
}

.s-icon-directions_transit:before {
    content: "\e535";
}

.s-icon-directions_walk:before {
    content: "\e536";
}

.s-icon-disc_full:before {
    content: "\e610";
}

.s-icon-dns:before {
    content: "\e875";
}

.s-icon-not_interested:before {
    content: "\e033";
}

.s-icon-do_not_disturb_alt:before {
    content: "\e611";
}

.s-icon-do_not_disturb_off:before {
    content: "\e643";
}

.s-icon-remove_circle:before {
    content: "\e15c";
}

.s-icon-dock:before {
    content: "\e30e";
}

.s-icon-done:before {
    content: "\e876";
}

.s-icon-done_all:before {
    content: "\e877";
}

.s-icon-donut_large:before {
    content: "\e917";
}

.s-icon-donut_small:before {
    content: "\e918";
}

.s-icon-drafts:before {
    content: "\e151";
}

.s-icon-drag_handle:before {
    content: "\e25d";
}

.s-icon-time_to_leave:before {
    content: "\e62c";
}

.s-icon-dvr:before {
    content: "\e1b2";
}

.s-icon-edit_location:before {
    content: "\e568";
}

.s-icon-eject2:before {
    content: "\e8fb";
}

.s-icon-markunread:before {
    content: "\e159";
}

.s-icon-enhanced_encryption:before {
    content: "\e63f";
}

.s-icon-equalizer:before {
    content: "\e01d";
}

.s-icon-error:before {
    content: "\e000";
}

.s-icon-error_outline:before {
    content: "\e001";
}

.s-icon-euro_symbol:before {
    content: "\e926";
}

.s-icon-ev_station:before {
    content: "\e56d";
}

.s-icon-insert_invitation:before {
    content: "\e24f";
}

.s-icon-event_available:before {
    content: "\e614";
}

.s-icon-event_busy:before {
    content: "\e615";
}

.s-icon-event_note:before {
    content: "\e616";
}

.s-icon-event_seat:before {
    content: "\e903";
}

.s-icon-exit_to_app:before {
    content: "\e879";
}

.s-icon-expand_less:before {
    content: "\e5ce";
}

.s-icon-expand_more:before {
    content: "\e5cf";
}

.s-icon-explicit:before {
    content: "\e01e";
}

.s-icon-explore:before {
    content: "\e87a";
}

.s-icon-exposure:before {
    content: "\e3ca";
}

.s-icon-exposure_neg_1:before {
    content: "\e3cb";
}

.s-icon-exposure_neg_2:before {
    content: "\e3cc";
}

.s-icon-exposure_plus_1:before {
    content: "\e3cd";
}

.s-icon-exposure_plus_2:before {
    content: "\e3ce";
}

.s-icon-exposure_zero:before {
    content: "\e3cf";
}

.s-icon-extension:before {
    content: "\e87b";
}

.s-icon-face:before {
    content: "\e87c";
}

.s-icon-fast_forward:before {
    content: "\e01f";
}

.s-icon-fast_rewind:before {
    content: "\e020";
}

.s-icon-favorite:before {
    content: "\e87d";
}

.s-icon-favorite_border:before {
    content: "\e87e";
}

.s-icon-featured_play_list:before {
    content: "\e06d";
}

.s-icon-featured_video:before {
    content: "\e06e";
}

.s-icon-sms_failed:before {
    content: "\e626";
}

.s-icon-fiber_dvr:before {
    content: "\e05d";
}

.s-icon-fiber_manual_record:before {
    content: "\e061";
}

.s-icon-fiber_new:before {
    content: "\e05e";
}

.s-icon-fiber_pin:before {
    content: "\e06a";
}

.s-icon-fiber_smart_record:before {
    content: "\e062";
}

.s-icon-get_app:before {
    content: "\e884";
}

.s-icon-file_upload:before {
    content: "\e2c6";
}

.s-icon-filter2:before {
    content: "\e3d3";
}

.s-icon-filter_1:before {
    content: "\e3d0";
}

.s-icon-filter_2:before {
    content: "\e3d1";
}

.s-icon-filter_3:before {
    content: "\e3d2";
}

.s-icon-filter_4:before {
    content: "\e3d4";
}

.s-icon-filter_5:before {
    content: "\e3d5";
}

.s-icon-filter_6:before {
    content: "\e3d6";
}

.s-icon-filter_7:before {
    content: "\e3d7";
}

.s-icon-filter_8:before {
    content: "\e3d8";
}

.s-icon-filter_9:before {
    content: "\e3d9";
}

.s-icon-filter_9_plus:before {
    content: "\e3da";
}

.s-icon-filter_b_and_w:before {
    content: "\e3db";
}

.s-icon-filter_center_focus:before {
    content: "\e3dc";
}

.s-icon-filter_drama:before {
    content: "\e3dd";
}

.s-icon-filter_frames:before {
    content: "\e3de";
}

.s-icon-terrain:before {
    content: "\e564";
}

.s-icon-filter_list:before {
    content: "\e152";
}

.s-icon-filter_none:before {
    content: "\e3e0";
}

.s-icon-filter_tilt_shift:before {
    content: "\e3e2";
}

.s-icon-filter_vintage:before {
    content: "\e3e3";
}

.s-icon-find_in_page:before {
    content: "\e880";
}

.s-icon-find_replace:before {
    content: "\e881";
}

.s-icon-fingerprint:before {
    content: "\e90d";
}

.s-icon-first_page:before {
    content: "\e5dc";
}

.s-icon-fitness_center:before {
    content: "\eb43";
}

.s-icon-flare:before {
    content: "\e3e4";
}

.s-icon-flash_auto:before {
    content: "\e3e5";
}

.s-icon-flash_off:before {
    content: "\e3e6";
}

.s-icon-flash_on:before {
    content: "\e3e7";
}

.s-icon-flight_land:before {
    content: "\e904";
}

.s-icon-flight_takeoff:before {
    content: "\e905";
}

.s-icon-flip:before {
    content: "\e3e8";
}

.s-icon-flip_to_back:before {
    content: "\e882";
}

.s-icon-flip_to_front:before {
    content: "\e883";
}

.s-icon-folder2:before {
    content: "\e2c7";
}

.s-icon-folder_open:before {
    content: "\e2c8";
}

.s-icon-folder_shared:before {
    content: "\e2c9";
}

.s-icon-folder_special:before {
    content: "\e617";
}

.s-icon-font_download:before {
    content: "\e167";
}

.s-icon-format_align_center:before {
    content: "\e234";
}

.s-icon-format_align_justify:before {
    content: "\e235";
}

.s-icon-format_align_left:before {
    content: "\e236";
}

.s-icon-format_align_right:before {
    content: "\e237";
}

.s-icon-format_bold:before {
    content: "\e238";
}

.s-icon-format_clear:before {
    content: "\e239";
}

.s-icon-format_color_fill:before {
    content: "\e23a";
}

.s-icon-format_color_reset:before {
    content: "\e23b";
}

.s-icon-format_color_text:before {
    content: "\e23c";
}

.s-icon-format_indent_decrease:before {
    content: "\e23d";
}

.s-icon-format_indent_increase:before {
    content: "\e23e";
}

.s-icon-format_italic:before {
    content: "\e23f";
}

.s-icon-format_line_spacing:before {
    content: "\e240";
}

.s-icon-format_list_bulleted:before {
    content: "\e241";
}

.s-icon-format_list_numbered:before {
    content: "\e242";
}

.s-icon-format_paint:before {
    content: "\e243";
}

.s-icon-format_quote:before {
    content: "\e244";
}

.s-icon-format_shapes:before {
    content: "\e25e";
}

.s-icon-format_size:before {
    content: "\e245";
}

.s-icon-format_strikethrough:before {
    content: "\e246";
}

.s-icon-format_textdirection_l_to_r:before {
    content: "\e247";
}

.s-icon-format_textdirection_r_to_l:before {
    content: "\e248";
}

.s-icon-format_underlined:before {
    content: "\e249";
}

.s-icon-question_answer:before {
    content: "\e8af";
}

.s-icon-forward2:before {
    content: "\e154";
}

.s-icon-forward_10:before {
    content: "\e056";
}

.s-icon-forward_30:before {
    content: "\e057";
}

.s-icon-forward_5:before {
    content: "\e058";
}

.s-icon-free_breakfast:before {
    content: "\eb44";
}

.s-icon-fullscreen:before {
    content: "\e5d0";
}

.s-icon-fullscreen_exit:before {
    content: "\e5d1";
}

.s-icon-functions:before {
    content: "\e24a";
}

.s-icon-g_translate:before {
    content: "\e927";
}

.s-icon-games:before {
    content: "\e021";
}

.s-icon-gavel2:before {
    content: "\e90e";
}

.s-icon-gesture:before {
    content: "\e155";
}

.s-icon-gif:before {
    content: "\e908";
}

.s-icon-goat:before {
    content: "\e900";
}

.s-icon-golf_course:before {
    content: "\eb45";
}

.s-icon-my_location:before {
    content: "\e55c";
}

.s-icon-location_searching:before {
    content: "\e1b7";
}

.s-icon-location_disabled:before {
    content: "\e1b6";
}

.s-icon-star2:before {
    content: "\e838";
}

.s-icon-gradient:before {
    content: "\e3e9";
}

.s-icon-grain:before {
    content: "\e3ea";
}

.s-icon-graphic_eq:before {
    content: "\e1b8";
}

.s-icon-grid_off:before {
    content: "\e3eb";
}

.s-icon-grid_on:before {
    content: "\e3ec";
}

.s-icon-people:before {
    content: "\e7fb";
}

.s-icon-group_add:before {
    content: "\e7f0";
}

.s-icon-group_work:before {
    content: "\e886";
}

.s-icon-hd:before {
    content: "\e052";
}

.s-icon-hdr_off:before {
    content: "\e3ed";
}

.s-icon-hdr_on:before {
    content: "\e3ee";
}

.s-icon-hdr_strong:before {
    content: "\e3f1";
}

.s-icon-hdr_weak:before {
    content: "\e3f2";
}

.s-icon-headset:before {
    content: "\e310";
}

.s-icon-headset_mic:before {
    content: "\e311";
}

.s-icon-healing:before {
    content: "\e3f3";
}

.s-icon-hearing:before {
    content: "\e023";
}

.s-icon-help:before {
    content: "\e887";
}

.s-icon-help_outline:before {
    content: "\e8fd";
}

.s-icon-high_quality:before {
    content: "\e024";
}

.s-icon-highlight:before {
    content: "\e25f";
}

.s-icon-highlight_off:before {
    content: "\e888";
}

.s-icon-restore:before {
    content: "\e8b3";
}

.s-icon-home2:before {
    content: "\e88a";
}

.s-icon-hot_tub:before {
    content: "\eb46";
}

.s-icon-local_hotel:before {
    content: "\e549";
}

.s-icon-hourglass_empty:before {
    content: "\e88b";
}

.s-icon-hourglass_full:before {
    content: "\e88c";
}

.s-icon-http:before {
    content: "\e902";
}

.s-icon-lock2:before {
    content: "\e897";
}

.s-icon-photo2:before {
    content: "\e410";
}

.s-icon-image_aspect_ratio:before {
    content: "\e3f5";
}

.s-icon-import_contacts:before {
    content: "\e0e0";
}

.s-icon-import_export:before {
    content: "\e0c3";
}

.s-icon-important_devices:before {
    content: "\e912";
}

.s-icon-inbox2:before {
    content: "\e156";
}

.s-icon-indeterminate_check_box:before {
    content: "\e909";
}

.s-icon-info2:before {
    content: "\e88e";
}

.s-icon-info_outline:before {
    content: "\e88f";
}

.s-icon-input:before {
    content: "\e890";
}

.s-icon-insert_comment:before {
    content: "\e24c";
}

.s-icon-insert_drive_file:before {
    content: "\e24d";
}

.s-icon-tag_faces:before {
    content: "\e420";
}

.s-icon-link2:before {
    content: "\e157";
}

.s-icon-invert_colors:before {
    content: "\e891";
}

.s-icon-invert_colors_off:before {
    content: "\e0c4";
}

.s-icon-iso:before {
    content: "\e3f6";
}

.s-icon-keyboard:before {
    content: "\e312";
}

.s-icon-keyboard_arrow_down:before {
    content: "\e313";
}

.s-icon-keyboard_arrow_left:before {
    content: "\e314";
}

.s-icon-keyboard_arrow_right:before {
    content: "\e315";
}

.s-icon-keyboard_arrow_up:before {
    content: "\e316";
}

.s-icon-keyboard_backspace:before {
    content: "\e317";
}

.s-icon-keyboard_capslock:before {
    content: "\e318";
}

.s-icon-keyboard_hide:before {
    content: "\e31a";
}

.s-icon-keyboard_return:before {
    content: "\e31b";
}

.s-icon-keyboard_tab:before {
    content: "\e31c";
}

.s-icon-keyboard_voice:before {
    content: "\e31d";
}

.s-icon-kitchen:before {
    content: "\eb47";
}

.s-icon-label:before {
    content: "\e892";
}

.s-icon-label_outline:before {
    content: "\e893";
}

.s-icon-language2:before {
    content: "\e894";
}

.s-icon-laptop_chromebook:before {
    content: "\e31f";
}

.s-icon-laptop_mac:before {
    content: "\e320";
}

.s-icon-laptop_windows:before {
    content: "\e321";
}

.s-icon-last_page:before {
    content: "\e5dd";
}

.s-icon-open_in_new:before {
    content: "\e89e";
}

.s-icon-layers:before {
    content: "\e53b";
}

.s-icon-layers_clear:before {
    content: "\e53c";
}

.s-icon-leak_add:before {
    content: "\e3f8";
}

.s-icon-leak_remove:before {
    content: "\e3f9";
}

.s-icon-lens:before {
    content: "\e3fa";
}

.s-icon-library_books:before {
    content: "\e02f";
}

.s-icon-library_music:before {
    content: "\e030";
}

.s-icon-lightbulb_outline:before {
    content: "\e90f";
}

.s-icon-line_style:before {
    content: "\e919";
}

.s-icon-line_weight:before {
    content: "\e91a";
}

.s-icon-linear_scale:before {
    content: "\e260";
}

.s-icon-linked_camera:before {
    content: "\e438";
}

.s-icon-list2:before {
    content: "\e896";
}

.s-icon-live_help:before {
    content: "\e0c6";
}

.s-icon-live_tv:before {
    content: "\e639";
}

.s-icon-local_play:before {
    content: "\e553";
}

.s-icon-local_airport:before {
    content: "\e53d";
}

.s-icon-local_atm:before {
    content: "\e53e";
}

.s-icon-local_bar:before {
    content: "\e540";
}

.s-icon-local_cafe:before {
    content: "\e541";
}

.s-icon-local_car_wash:before {
    content: "\e542";
}

.s-icon-local_convenience_store:before {
    content: "\e543";
}

.s-icon-restaurant_menu:before {
    content: "\e561";
}

.s-icon-local_drink:before {
    content: "\e544";
}

.s-icon-local_florist:before {
    content: "\e545";
}

.s-icon-local_gas_station:before {
    content: "\e546";
}

.s-icon-shopping_cart:before {
    content: "\e8cc";
}

.s-icon-local_hospital:before {
    content: "\e548";
}

.s-icon-local_laundry_service:before {
    content: "\e54a";
}

.s-icon-local_library:before {
    content: "\e54b";
}

.s-icon-local_mall:before {
    content: "\e54c";
}

.s-icon-theaters:before {
    content: "\e8da";
}

.s-icon-local_offer:before {
    content: "\e54e";
}

.s-icon-local_parking:before {
    content: "\e54f";
}

.s-icon-local_pharmacy:before {
    content: "\e550";
}

.s-icon-local_pizza:before {
    content: "\e552";
}

.s-icon-print2:before {
    content: "\e8ad";
}

.s-icon-local_shipping:before {
    content: "\e558";
}

.s-icon-local_taxi:before {
    content: "\e559";
}

.s-icon-location_city:before {
    content: "\e7f1";
}

.s-icon-location_off:before {
    content: "\e0c7";
}

.s-icon-room:before {
    content: "\e8b4";
}

.s-icon-lock_open:before {
    content: "\e898";
}

.s-icon-lock_outline:before {
    content: "\e899";
}

.s-icon-looks:before {
    content: "\e3fc";
}

.s-icon-looks_3:before {
    content: "\e3fb";
}

.s-icon-looks_4:before {
    content: "\e3fd";
}

.s-icon-looks_5:before {
    content: "\e3fe";
}

.s-icon-looks_6:before {
    content: "\e3ff";
}

.s-icon-looks_one:before {
    content: "\e400";
}

.s-icon-looks_two:before {
    content: "\e401";
}

.s-icon-sync:before {
    content: "\e627";
}

.s-icon-loupe:before {
    content: "\e402";
}

.s-icon-low_priority:before {
    content: "\e16d";
}

.s-icon-loyalty:before {
    content: "\e89a";
}

.s-icon-mail_outline:before {
    content: "\e0e1";
}

.s-icon-map2:before {
    content: "\e55b";
}

.s-icon-markunread_mailbox:before {
    content: "\e89b";
}

.s-icon-memory:before {
    content: "\e322";
}

.s-icon-menu:before {
    content: "\e5d2";
}

.s-icon-message:before {
    content: "\e0c9";
}

.s-icon-mic:before {
    content: "\e029";
}

.s-icon-mic_none:before {
    content: "\e02a";
}

.s-icon-mic_off:before {
    content: "\e02b";
}

.s-icon-mms:before {
    content: "\e618";
}

.s-icon-mode_comment:before {
    content: "\e253";
}

.s-icon-monetization_on:before {
    content: "\e263";
}

.s-icon-money_off:before {
    content: "\e25c";
}

.s-icon-monochrome_photos:before {
    content: "\e403";
}

.s-icon-mood_bad:before {
    content: "\e7f3";
}

.s-icon-more:before {
    content: "\e619";
}

.s-icon-more_horiz:before {
    content: "\e5d3";
}

.s-icon-more_vert:before {
    content: "\e5d4";
}

.s-icon-motorcycle2:before {
    content: "\e91b";
}

.s-icon-mouse:before {
    content: "\e323";
}

.s-icon-move_to_inbox:before {
    content: "\e168";
}

.s-icon-movie_creation:before {
    content: "\e404";
}

.s-icon-movie_filter:before {
    content: "\e43a";
}

.s-icon-multiline_chart:before {
    content: "\e6df";
}

.s-icon-music_note:before {
    content: "\e405";
}

.s-icon-music_video:before {
    content: "\e063";
}

.s-icon-nature:before {
    content: "\e406";
}

.s-icon-nature_people:before {
    content: "\e407";
}

.s-icon-navigation:before {
    content: "\e55d";
}

.s-icon-near_me:before {
    content: "\e569";
}

.s-icon-network_cell:before {
    content: "\e1b9";
}

.s-icon-network_check:before {
    content: "\e640";
}

.s-icon-network_locked:before {
    content: "\e61a";
}

.s-icon-network_wifi:before {
    content: "\e1ba";
}

.s-icon-new_releases:before {
    content: "\e031";
}

.s-icon-next_week:before {
    content: "\e16a";
}

.s-icon-nfc:before {
    content: "\e1bb";
}

.s-icon-no_encryption:before {
    content: "\e641";
}

.s-icon-signal_cellular_no_sim:before {
    content: "\e1ce";
}

.s-icon-note:before {
    content: "\e06f";
}

.s-icon-note_add:before {
    content: "\e89c";
}

.s-icon-notifications:before {
    content: "\e7f4";
}

.s-icon-notifications_active:before {
    content: "\e7f7";
}

.s-icon-notifications_none:before {
    content: "\e7f5";
}

.s-icon-notifications_off:before {
    content: "\e7f6";
}

.s-icon-notifications_paused:before {
    content: "\e7f8";
}

.s-icon-offline_pin:before {
    content: "\e90a";
}

.s-icon-ondemand_video:before {
    content: "\e63a";
}

.s-icon-opacity:before {
    content: "\e91c";
}

.s-icon-open_in_browser:before {
    content: "\e89d";
}

.s-icon-open_with:before {
    content: "\e89f";
}

.s-icon-pages:before {
    content: "\e7f9";
}

.s-icon-pageview:before {
    content: "\e8a0";
}

.s-icon-pan_tool:before {
    content: "\e925";
}

.s-icon-panorama:before {
    content: "\e40b";
}

.s-icon-radio_button_unchecked:before {
    content: "\e836";
}

.s-icon-panorama_horizontal:before {
    content: "\e40d";
}

.s-icon-panorama_vertical:before {
    content: "\e40e";
}

.s-icon-panorama_wide_angle:before {
    content: "\e40f";
}

.s-icon-party_mode:before {
    content: "\e7fa";
}

.s-icon-pause2:before {
    content: "\e034";
}

.s-icon-pause_circle_filled:before {
    content: "\e035";
}

.s-icon-pause_circle_outline:before {
    content: "\e036";
}

.s-icon-people_outline:before {
    content: "\e7fc";
}

.s-icon-perm_camera_mic:before {
    content: "\e8a2";
}

.s-icon-perm_contact_calendar:before {
    content: "\e8a3";
}

.s-icon-perm_data_setting:before {
    content: "\e8a4";
}

.s-icon-perm_device_information:before {
    content: "\e8a5";
}

.s-icon-person_outline:before {
    content: "\e7ff";
}

.s-icon-perm_media:before {
    content: "\e8a7";
}

.s-icon-perm_phone_msg:before {
    content: "\e8a8";
}

.s-icon-perm_scan_wifi:before {
    content: "\e8a9";
}

.s-icon-person:before {
    content: "\e7fd";
}

.s-icon-person_add:before {
    content: "\e7fe";
}

.s-icon-person_pin:before {
    content: "\e55a";
}

.s-icon-person_pin_circle:before {
    content: "\e56a";
}

.s-icon-personal_video:before {
    content: "\e63b";
}

.s-icon-pets:before {
    content: "\e91d";
}

.s-icon-phone_android:before {
    content: "\e324";
}

.s-icon-phone_bluetooth_speaker:before {
    content: "\e61b";
}

.s-icon-phone_forwarded:before {
    content: "\e61c";
}

.s-icon-phone_in_talk:before {
    content: "\e61d";
}

.s-icon-phone_iphone:before {
    content: "\e325";
}

.s-icon-phone_locked:before {
    content: "\e61e";
}

.s-icon-phone_missed:before {
    content: "\e61f";
}

.s-icon-phone_paused:before {
    content: "\e620";
}

.s-icon-phonelink_erase:before {
    content: "\e0db";
}

.s-icon-phonelink_lock:before {
    content: "\e0dc";
}

.s-icon-phonelink_off:before {
    content: "\e327";
}

.s-icon-phonelink_ring:before {
    content: "\e0dd";
}

.s-icon-phonelink_setup:before {
    content: "\e0de";
}

.s-icon-photo_album:before {
    content: "\e411";
}

.s-icon-photo_filter:before {
    content: "\e43b";
}

.s-icon-photo_size_select_actual:before {
    content: "\e432";
}

.s-icon-photo_size_select_large:before {
    content: "\e433";
}

.s-icon-photo_size_select_small:before {
    content: "\e434";
}

.s-icon-picture_as_pdf:before {
    content: "\e415";
}

.s-icon-picture_in_picture:before {
    content: "\e8aa";
}

.s-icon-picture_in_picture_alt:before {
    content: "\e911";
}

.s-icon-pie_chart:before {
    content: "\e6c4";
}

.s-icon-pie_chart_outlined:before {
    content: "\e6c5";
}

.s-icon-pin_drop:before {
    content: "\e55e";
}

.s-icon-play_arrow:before {
    content: "\e037";
}

.s-icon-play_circle_filled:before {
    content: "\e038";
}

.s-icon-play_circle_outline:before {
    content: "\e039";
}

.s-icon-play_for_work:before {
    content: "\e906";
}

.s-icon-playlist_add:before {
    content: "\e03b";
}

.s-icon-playlist_add_check:before {
    content: "\e065";
}

.s-icon-playlist_play:before {
    content: "\e05f";
}

.s-icon-plus_one:before {
    content: "\e800";
}

.s-icon-polymer:before {
    content: "\e8ab";
}

.s-icon-pool:before {
    content: "\eb48";
}

.s-icon-portable_wifi_off:before {
    content: "\e0ce";
}

.s-icon-portrait:before {
    content: "\e416";
}

.s-icon-power:before {
    content: "\e63c";
}

.s-icon-power_input:before {
    content: "\e336";
}

.s-icon-power_settings_new:before {
    content: "\e8ac";
}

.s-icon-pregnant_woman:before {
    content: "\e91e";
}

.s-icon-present_to_all:before {
    content: "\e0df";
}

.s-icon-priority_high:before {
    content: "\e645";
}

.s-icon-public:before {
    content: "\e80b";
}

.s-icon-publish:before {
    content: "\e255";
}

.s-icon-queue_music:before {
    content: "\e03d";
}

.s-icon-queue_play_next:before {
    content: "\e066";
}

.s-icon-radio:before {
    content: "\e03e";
}

.s-icon-radio_button_checked:before {
    content: "\e837";
}

.s-icon-rate_review:before {
    content: "\e560";
}

.s-icon-receipt:before {
    content: "\e8b0";
}

.s-icon-recent_actors:before {
    content: "\e03f";
}

.s-icon-record_voice_over:before {
    content: "\e91f";
}

.s-icon-redo:before {
    content: "\e15a";
}

.s-icon-refresh2:before {
    content: "\e5d5";
}

.s-icon-remove2:before {
    content: "\e15b";
}

.s-icon-remove_circle_outline:before {
    content: "\e15d";
}

.s-icon-remove_from_queue:before {
    content: "\e067";
}

.s-icon-visibility:before {
    content: "\e8f4";
}

.s-icon-remove_shopping_cart:before {
    content: "\e928";
}

.s-icon-reorder2:before {
    content: "\e8fe";
}

.s-icon-repeat2:before {
    content: "\e040";
}

.s-icon-repeat_one:before {
    content: "\e041";
}

.s-icon-replay:before {
    content: "\e042";
}

.s-icon-replay_10:before {
    content: "\e059";
}

.s-icon-replay_30:before {
    content: "\e05a";
}

.s-icon-replay_5:before {
    content: "\e05b";
}

.s-icon-reply2:before {
    content: "\e15e";
}

.s-icon-reply_all:before {
    content: "\e15f";
}

.s-icon-report:before {
    content: "\e160";
}

.s-icon-warning2:before {
    content: "\e002";
}

.s-icon-restaurant:before {
    content: "\e56c";
}

.s-icon-restore_page:before {
    content: "\e929";
}

.s-icon-ring_volume:before {
    content: "\e0d1";
}

.s-icon-room_service:before {
    content: "\eb49";
}

.s-icon-rotate_90_degrees_ccw:before {
    content: "\e418";
}

.s-icon-rotate_left:before {
    content: "\e419";
}

.s-icon-rotate_right:before {
    content: "\e41a";
}

.s-icon-rounded_corner:before {
    content: "\e920";
}

.s-icon-router:before {
    content: "\e328";
}

.s-icon-rowing:before {
    content: "\e921";
}

.s-icon-rss_feed:before {
    content: "\e0e5";
}

.s-icon-rv_hookup:before {
    content: "\e642";
}

.s-icon-satellite:before {
    content: "\e562";
}

.s-icon-save2:before {
    content: "\e161";
}

.s-icon-scanner:before {
    content: "\e329";
}

.s-icon-school:before {
    content: "\e80c";
}

.s-icon-screen_lock_landscape:before {
    content: "\e1be";
}

.s-icon-screen_lock_portrait:before {
    content: "\e1bf";
}

.s-icon-screen_lock_rotation:before {
    content: "\e1c0";
}

.s-icon-screen_rotation:before {
    content: "\e1c1";
}

.s-icon-screen_share:before {
    content: "\e0e2";
}

.s-icon-sd_storage:before {
    content: "\e1c2";
}

.s-icon-search2:before {
    content: "\e8b6";
}

.s-icon-security:before {
    content: "\e32a";
}

.s-icon-select_all:before {
    content: "\e162";
}

.s-icon-send2:before {
    content: "\e163";
}

.s-icon-sentiment_dissatisfied:before {
    content: "\e811";
}

.s-icon-sentiment_neutral:before {
    content: "\e812";
}

.s-icon-sentiment_satisfied:before {
    content: "\e813";
}

.s-icon-sentiment_very_dissatisfied:before {
    content: "\e814";
}

.s-icon-sentiment_very_satisfied:before {
    content: "\e815";
}

.s-icon-settings:before {
    content: "\e8b8";
}

.s-icon-settings_applications:before {
    content: "\e8b9";
}

.s-icon-settings_backup_restore:before {
    content: "\e8ba";
}

.s-icon-settings_bluetooth:before {
    content: "\e8bb";
}

.s-icon-settings_brightness:before {
    content: "\e8bd";
}

.s-icon-settings_cell:before {
    content: "\e8bc";
}

.s-icon-settings_ethernet:before {
    content: "\e8be";
}

.s-icon-settings_input_antenna:before {
    content: "\e8bf";
}

.s-icon-settings_input_composite:before {
    content: "\e8c1";
}

.s-icon-settings_input_hdmi:before {
    content: "\e8c2";
}

.s-icon-settings_input_svideo:before {
    content: "\e8c3";
}

.s-icon-settings_overscan:before {
    content: "\e8c4";
}

.s-icon-settings_phone:before {
    content: "\e8c5";
}

.s-icon-settings_power:before {
    content: "\e8c6";
}

.s-icon-settings_remote:before {
    content: "\e8c7";
}

.s-icon-settings_system_daydream:before {
    content: "\e1c3";
}

.s-icon-settings_voice:before {
    content: "\e8c8";
}

.s-icon-share2:before {
    content: "\e80d";
}

.s-icon-shop:before {
    content: "\e8c9";
}

.s-icon-shop_two:before {
    content: "\e8ca";
}

.s-icon-shopping_basket:before {
    content: "\e8cb";
}

.s-icon-short_text:before {
    content: "\e261";
}

.s-icon-show_chart:before {
    content: "\e6e1";
}

.s-icon-shuffle:before {
    content: "\e043";
}

.s-icon-signal_cellular_4_bar:before {
    content: "\e1c8";
}

.s-icon-signal_cellular_connected_no_internet_4_bar:before {
    content: "\e1cd";
}

.s-icon-signal_cellular_null:before {
    content: "\e1cf";
}

.s-icon-signal_cellular_off:before {
    content: "\e1d0";
}

.s-icon-signal_wifi_4_bar:before {
    content: "\e1d8";
}

.s-icon-signal_wifi_4_bar_lock:before {
    content: "\e1d9";
}

.s-icon-signal_wifi_off:before {
    content: "\e1da";
}

.s-icon-sim_card:before {
    content: "\e32b";
}

.s-icon-sim_card_alert:before {
    content: "\e624";
}

.s-icon-skip_next:before {
    content: "\e044";
}

.s-icon-skip_previous:before {
    content: "\e045";
}

.s-icon-slideshow:before {
    content: "\e41b";
}

.s-icon-slow_motion_video:before {
    content: "\e068";
}

.s-icon-stay_primary_portrait:before {
    content: "\e0d6";
}

.s-icon-smoke_free:before {
    content: "\eb4a";
}

.s-icon-smoking_rooms:before {
    content: "\eb4b";
}

.s-icon-textsms:before {
    content: "\e0d8";
}

.s-icon-snooze:before {
    content: "\e046";
}

.s-icon-sort2:before {
    content: "\e164";
}

.s-icon-sort_by_alpha:before {
    content: "\e053";
}

.s-icon-spa:before {
    content: "\eb4c";
}

.s-icon-space_bar:before {
    content: "\e256";
}

.s-icon-speaker:before {
    content: "\e32d";
}

.s-icon-speaker_group:before {
    content: "\e32e";
}

.s-icon-speaker_notes:before {
    content: "\e8cd";
}

.s-icon-speaker_notes_off:before {
    content: "\e92a";
}

.s-icon-speaker_phone:before {
    content: "\e0d2";
}

.s-icon-spellcheck:before {
    content: "\e8ce";
}

.s-icon-star_border:before {
    content: "\e83a";
}

.s-icon-star_half:before {
    content: "\e839";
}

.s-icon-stars:before {
    content: "\e8d0";
}

.s-icon-stay_primary_landscape:before {
    content: "\e0d5";
}

.s-icon-stop2:before {
    content: "\e047";
}

.s-icon-stop_screen_share:before {
    content: "\e0e3";
}

.s-icon-storage:before {
    content: "\e1db";
}

.s-icon-store_mall_directory:before {
    content: "\e563";
}

.s-icon-straighten:before {
    content: "\e41c";
}

.s-icon-streetview:before {
    content: "\e56e";
}

.s-icon-strikethrough_s:before {
    content: "\e257";
}

.s-icon-style:before {
    content: "\e41d";
}

.s-icon-subdirectory_arrow_left:before {
    content: "\e5d9";
}

.s-icon-subdirectory_arrow_right:before {
    content: "\e5da";
}

.s-icon-subject:before {
    content: "\e8d2";
}

.s-icon-subscriptions:before {
    content: "\e064";
}

.s-icon-subtitles:before {
    content: "\e048";
}

.s-icon-subway2:before {
    content: "\e56f";
}

.s-icon-supervisor_account:before {
    content: "\e8d3";
}

.s-icon-surround_sound:before {
    content: "\e049";
}

.s-icon-swap_calls:before {
    content: "\e0d7";
}

.s-icon-swap_horiz:before {
    content: "\e8d4";
}

.s-icon-swap_vert:before {
    content: "\e8d5";
}

.s-icon-swap_vertical_circle:before {
    content: "\e8d6";
}

.s-icon-switch_camera:before {
    content: "\e41e";
}

.s-icon-switch_video:before {
    content: "\e41f";
}

.s-icon-sync_disabled:before {
    content: "\e628";
}

.s-icon-sync_problem:before {
    content: "\e629";
}

.s-icon-system_update:before {
    content: "\e62a";
}

.s-icon-system_update_alt:before {
    content: "\e8d7";
}

.s-icon-tab:before {
    content: "\e8d8";
}

.s-icon-tab_unselected:before {
    content: "\e8d9";
}

.s-icon-tablet2:before {
    content: "\e32f";
}

.s-icon-tablet_android:before {
    content: "\e330";
}

.s-icon-tablet_mac:before {
    content: "\e331";
}

.s-icon-tap_and_play:before {
    content: "\e62b";
}

.s-icon-text_fields:before {
    content: "\e262";
}

.s-icon-text_format:before {
    content: "\e165";
}

.s-icon-texture:before {
    content: "\e421";
}

.s-icon-thumb_down:before {
    content: "\e8db";
}

.s-icon-thumb_up:before {
    content: "\e8dc";
}

.s-icon-thumbs_up_down:before {
    content: "\e8dd";
}

.s-icon-timelapse:before {
    content: "\e422";
}

.s-icon-timeline:before {
    content: "\e922";
}

.s-icon-timer:before {
    content: "\e425";
}

.s-icon-timer_10:before {
    content: "\e423";
}

.s-icon-timer_3:before {
    content: "\e424";
}

.s-icon-timer_off:before {
    content: "\e426";
}

.s-icon-title:before {
    content: "\e264";
}

.s-icon-toc:before {
    content: "\e8de";
}

.s-icon-today:before {
    content: "\e8df";
}

.s-icon-toll:before {
    content: "\e8e0";
}

.s-icon-tonality:before {
    content: "\e427";
}

.s-icon-touch_app:before {
    content: "\e913";
}

.s-icon-toys:before {
    content: "\e332";
}

.s-icon-track_changes:before {
    content: "\e8e1";
}

.s-icon-traffic:before {
    content: "\e565";
}

.s-icon-train2:before {
    content: "\e570";
}

.s-icon-tram:before {
    content: "\e571";
}

.s-icon-transfer_within_a_station:before {
    content: "\e572";
}

.s-icon-transform:before {
    content: "\e428";
}

.s-icon-translate:before {
    content: "\e8e2";
}

.s-icon-trending_down:before {
    content: "\e8e3";
}

.s-icon-trending_flat:before {
    content: "\e8e4";
}

.s-icon-trending_up:before {
    content: "\e8e5";
}

.s-icon-tune:before {
    content: "\e429";
}

.s-icon-tv2:before {
    content: "\e333";
}

.s-icon-unarchive:before {
    content: "\e169";
}

.s-icon-undo2:before {
    content: "\e166";
}

.s-icon-unfold_less:before {
    content: "\e5d6";
}

.s-icon-unfold_more:before {
    content: "\e5d7";
}

.s-icon-update:before {
    content: "\e923";
}

.s-icon-usb2:before {
    content: "\e1e0";
}

.s-icon-verified_user:before {
    content: "\e8e8";
}

.s-icon-vertical_align_bottom:before {
    content: "\e258";
}

.s-icon-vertical_align_center:before {
    content: "\e259";
}

.s-icon-vertical_align_top:before {
    content: "\e25a";
}

.s-icon-vibration:before {
    content: "\e62d";
}

.s-icon-video_call:before {
    content: "\e070";
}

.s-icon-video_label:before {
    content: "\e071";
}

.s-icon-video_library:before {
    content: "\e04a";
}

.s-icon-videocam:before {
    content: "\e04b";
}

.s-icon-videocam_off:before {
    content: "\e04c";
}

.s-icon-videogame_asset:before {
    content: "\e338";
}

.s-icon-view_agenda:before {
    content: "\e8e9";
}

.s-icon-view_array:before {
    content: "\e8ea";
}

.s-icon-view_carousel:before {
    content: "\e8eb";
}

.s-icon-view_column:before {
    content: "\e8ec";
}

.s-icon-view_comfy:before {
    content: "\e42a";
}

.s-icon-view_compact:before {
    content: "\e42b";
}

.s-icon-view_day:before {
    content: "\e8ed";
}

.s-icon-view_headline:before {
    content: "\e8ee";
}

.s-icon-view_list:before {
    content: "\e8ef";
}

.s-icon-view_module:before {
    content: "\e8f0";
}

.s-icon-view_quilt:before {
    content: "\e8f1";
}

.s-icon-view_stream:before {
    content: "\e8f2";
}

.s-icon-view_week:before {
    content: "\e8f3";
}

.s-icon-vignette:before {
    content: "\e435";
}

.s-icon-visibility_off:before {
    content: "\e8f5";
}

.s-icon-voice_chat:before {
    content: "\e62e";
}

.s-icon-voicemail:before {
    content: "\e0d9";
}

.s-icon-volume_down:before {
    content: "\e04d";
}

.s-icon-volume_mute:before {
    content: "\e04e";
}

.s-icon-volume_off:before {
    content: "\e04f";
}

.s-icon-volume_up:before {
    content: "\e050";
}

. create-account {
    text-align: center;
    margin: 0 auto;
}

.s-icon-vpn_key:before {
    content: "\e0da";
}

.s-icon-vpn_lock:before {
    content: "\e62f";
}

.s-icon-wallpaper:before {
    content: "\e1bc";
}

.s-icon-watch:before {
    content: "\e334";
}

.s-icon-watch_later:before {
    content: "\e924";
}

.s-icon-wb_auto:before {
    content: "\e42c";
}

.s-icon-wb_incandescent:before {
    content: "\e42e";
}

.s-icon-wb_iridescent:before {
    content: "\e436";
}

.s-icon-wb_sunny:before {
    content: "\e430";
}

.s-icon-wc:before {
    content: "\e63d";
}

.s-icon-web:before {
    content: "\e051";
}

.s-icon-web_asset:before {
    content: "\e069";
}

.s-icon-weekend:before {
    content: "\e16b";
}

.s-icon-whatshot:before {
    content: "\e80e";
}

.s-icon-widgets:before {
    content: "\e1bd";
}

.s-icon-wifi2:before {
    content: "\e63e";
}

.s-icon-wifi_lock:before {
    content: "\e1e1";
}

.s-icon-wifi_tethering:before {
    content: "\e1e2";
}

.s-icon-work:before {
    content: "\e8f9";
}

.s-icon-wrap_text:before {
    content: "\e25b";
}

.s-icon-youtube_searched_for:before {
    content: "\e8fa";
}

.s-icon-zoom_in:before {
    content: "\e8ff";
}

.s-icon-zoom_out:before {
    content: "\e901";
}

.s-icon-zoom_out_map:before {
    content: "\e56b";
}

.social-media {
    margin-bottom: 50px;
}
.social-media h5 {
    margin: 20px auto;
    color: gray;
}
.login-with-email h5 {
    margin: 20px auto;
    color: gray;
}

.social-login {
    width: 70%;
    margin: 0 auto;
}