.BookList {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.BookList_title {
    margin-bottom: 30px;
    display: block;
    width: 100%;
}