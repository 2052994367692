.BookTitle {
    font-size: 12px;
    margin-top: 20px;
    line-height: 1.5;
    text-align: center;
}

.BookImage {
    width: 100%;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
}

.Book {
    margin: 10px 15px 10px 0;
    text-align: left;
    width: 170px;
}

@media only screen and (max-width: 500px) {
    .Book {
        margin: 10px 15px 10px 10px;
    }
}
.tags {
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
}

.tags li {
    float: left;
}

.tag {
    background: #eee;
    border-radius: 3px 0 0 3px;
    color: #999;
    display: inline-block;
    height: 26px;
    line-height: 26px;
    padding: 0 20px 0 23px;
    position: relative;
    margin: 0 10px 10px 0;
    text-decoration: none;
    -webkit-transition: color 0.2s;
}

.tag::before {
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    content: '';
    height: 6px;
    left: 10px;
    position: absolute;
    width: 6px;
    top: 10px;
}

.tag::after {
    background: #fff;
    border-bottom: 13px solid transparent;
    border-left: 10px solid #eee;
    border-top: 13px solid transparent;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
}

.tag:hover {
    background-color: crimson;
    color: white;
}

.tag:hover::after {
    border-left-color: crimson;
}
