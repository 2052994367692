@import url('https://fonts.googleapis.com/css?family=Special+Elite');

.CheckoutForm-couponapplied {
    font-weight: bold;
    color: #32A8EE;
    display: inline-block;
    margin-left: 20px;
}
.Pricing-icon-img {
    width: 50px;
    height: auto;
}
.CheckoutForm_addons {
    padding: 20px;
    border: 1px solid #dddddd;
    margin: 0 0 20px 0;
}

.Checkout_CouponSuccess {
    background-image: url(/images/gift.svg);
    background-position: 10px center;
    background-size: 40px auto;
    background-color: #FEF3BD;
    background-repeat: no-repeat;
    padding: 20px;
    padding-left: 70px;
    border-radius: 8px;
    text-align: left;
}

.Checkout_Gift {
    background-color: #d6f6ff;
}

.CheckoutForm_addons h4 {
    margin-bottom: 20px;
}
.Pricing-td-info-free-trial {
    margin-top: 15px;
}
.CheckoutForm-quantity {
    width: 120px;
}

.CheckoutForm_MoneyBack {
    margin-bottom: 30px;
}

.CheckoutForm form {
    margin: 0 auto;
    padding: 0 15px;
}

.CheckoutForm .stripe-method-container input, .CheckoutForm .stripe-method-container button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
}

.CheckoutForm input:-webkit-autofill {
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
}

.CheckoutForm #example4-card {
    padding: 10px;
    margin-bottom: 2px;
}

.CheckoutForm input {
    -webkit-animation: 1ms void-animation-out;
}

.CheckoutForm input::-webkit-input-placeholder {
    color: #9bacc8;
}

.CheckoutForm input::-moz-placeholder {
    color: #9bacc8;
}

.CheckoutForm input:-ms-input-placeholder {
    color: #9bacc8;
}

.CheckoutForm .error svg .base {
    fill: #e25950;
}

.CheckoutForm .error svg .glyph {
    fill: #f6f9fc;
}

.CheckoutForm .error .message {
    color: #e25950;
}

.CheckoutForm .success .icon .border {
    stroke: #ffc7ee;
}

.CheckoutForm .success .icon .checkmark {
    stroke: #d782d9;
}

.CheckoutForm .success .title {
    color: #32325d;
}

.CheckoutForm .success .message {
    color: #8898aa;
}

.CheckoutForm .success .reset path {
    fill: #d782d9;
}

.PremiumFeatures_list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    color: black;
}

.pricingFeatureList li {
    background-image: url(../../assets/images/tick.svg);
    background-repeat: no-repeat;
    background-size: 1.25em 1.5em;
    list-style-type: none;
    padding-top: 2px;
    padding-right: 0px;
    padding-left: 2.5em;
    font-size: 1em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    padding-bottom: 12px;
}

.Pricing-start-free-trial {
    max-width: 581px;
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: -1px;
    text-align: left;
    color: #001a49;
    margin-top: 50px;
}

.Pricing-go-premium {
    margin-top: 30px;
    font-size: 28px;
}

.Pricing-line {
    width: 166px;
    height: 10px;
    background-color: #32a8ee;
    margin-top: 35px;
    margin-bottom: 60px;
}

.Pricing .press {
    margin-top: 80px;
}

.Pricing h3 {
    margin: 60px 0 20px 0;
    text-align: center;
}
.Pricing h4 {
    margin: 20px 0 20px 0;
}
.CheckoutForm .PaymentGroup {
    text-align: left;
}

.CheckoutForm .PaymentGroup .radio-group {
    padding: 16px;
    border: 1px solid #efeff0;
    border-bottom: 0;
    cursor: pointer;
}

.CheckoutForm .PaymentGroup textarea {
    height: 150px;

}

.CheckoutForm .PaymentGroup .radio-group:last-of-type {
    border-bottom: 1px solid #efeff0;
}


.CheckoutForm .PaymentGroup .radio-group input {
    appearance: auto;
    margin-right: 10px;
}
.CheckoutForm .PaymentGroup .section-header {
    border: 1px solid #efeff0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    align-items: flex-start;
    background-color: #fafafa;
    border-bottom: 1px solid #efeff0;
    font-weight: bold;
    padding: 16px;
}

.coupon-code {
    min-width: 200px;
}

.payment-method-container {
    padding: 5px;
    border: 1px solid #dde3e8;
    margin-top: 20px;
}

.Pricing .collapsible-header {
    font-family: CeraPro, "Helvetica Neue", "Arial", sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #001a49;
    cursor: pointer;
}

.Pricing .collapsible-content-wrapper {
    padding: 28px 37px 40px 40px;
    border-radius: 10px;
    border: solid 1px #ededed;
    margin: 10px auto;
}

.Pricing .collapsible-content {
    margin: 20px 0px 0 0;
    font-family: CeraPro, "Helvetica Neue", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: #4f596a;
}

.Pricing-enterprise-plan {
    margin: 80px auto;
    padding: 50px;
    background-color: #ededed;
    position: relative;
}

.Pricing_Main .Pricing-are-you-a-business-hdr {
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #123048;
    margin-bottom: 30px;
}

.Pricing-enterprise-plan-info {
    max-width: 805px;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.45px;
    text-align: left;
    color: #123048;
}

.Pricing_Main {
    max-width: 1000px;
    margin: 0 auto;
}

.Pricing-td {
    display: inline-block;
    width: 28%;
    max-width: 450px;
    height: 453px;
    padding: 48px 77px 40px 30px;
    background-color: #f5fbfe;
    margin-right: 20px;
    position: relative;
    min-width: 280px;
    vertical-align: top;
}

.Pricing-td-header {
    font-family: CeraPro, "Helvetica Neue", "Arial", sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.45px;
    text-align: left;
    color: #123048;
}

.Pricing-td-price {
    font-family: CeraPro, "Helvetica Neue", "Arial", sans-serif;
    font-size: 40px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #001a49;
    margin-top: 30px;
}

.Pricing-td-info {
    font-family: CeraPro, "Helvetica Neue", "Arial", sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.45px;
    text-align: left;
    margin-top: 60px;
    color: #123048;
    text-transform: uppercase;
}
.Pricing-td-buy-now {
    padding: 8px 69px 12px 70px;
    border-radius: 33px;
    border: solid 2px #32a8ee;
    position: absolute;
    bottom: 40px;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #32a8ee;
    font-weight: bold;
    background-color: transparent;
}
.Pricing-td-moyr {
    font-family: CeraPro, "Helvetica Neue", "Arial", sans-serif;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.4;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 26, 73, 0.5);
}
.Pricing_Main .Pricing-premium-features-hdr {
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #123048;
    margin-top: 85px;
}
.Pricing_Main .Pricing-why-sumizeit-hdr {
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #123048;
    margin-top: 100px;
}
.Pricing-rating-star {
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.57px;
    text-align: left;
    color: #0e2131;
}

.Pricing-free {
    position: relative;
    padding: 35px 35px 35px 30px;
    border: solid 2px #f5fbfe;
    margin-top: 20px;
}
.Pricing-free-access {
    font-family: CeraPro, "Helvetica Neue", "Arial", sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.45px;
    text-align: left;
    color: #123048;
}

.Pricing-free-btn {
    padding: 12px 41px 12px 42px;
    border-radius: 33px;
    background-color: #32a8ee;
    position: absolute;
    right: 35px;
    top: 58px;
    color: white;
    font-weight: bold;
    border: none;
}

.Pricing-benefits {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.Pricing-icon {
    vertical-align: top;
}
.Pricing-benefit {
    width: 250px;
    margin-top: 70px;
    margin-right: 40px;
    text-align: center;
    vertical-align: middle;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.76;
    letter-spacing: normal;
    color: #4f596a;
}

.Pricing-rating {
    margin: 80px auto 0 auto;
}

.Pricing-rating-out-of {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    text-transform: uppercase;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.57px;
    text-align: left;
    color: #0e2131;
}

.Pricing-rating-denom {
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.57px;
    text-align: left;
    color: #0e2131;
}
.Pricing-rating-out-of {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.57px;
    text-align: left;
    color: #0e2131;
}

.Pricing-testi {
    padding: 79.5px 0 80px 0;
    background-color: #32a8ee;
    margin-top: 100px;
}

.Pricing-testi-content {
    margin: 0 auto 45px auto;
    padding: 0 80px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.23px;
    text-align: left;
    color: #ffffff;
}

.Pricing_Main .Pricing-faq-hdr {
    margin-top: 100px;
    margin-bottom: 75px;
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #123048;
}

.Pricing-testi blockquote {
    position: relative;
    margin: 0 0 45px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.23px;
    text-align: left;
    color: #ffffff;
}

.Pricing-testi blockquote:before,
.Pricing-testi blockquote:after {
    position: absolute;
    color: #f1efe6;
    opacity: 0.3;
    font-family: 'Special Elite', cursive;
    font-size: 8rem;
    width: 4rem;
    height: 4rem;
}

.Pricing-testi blockquote:before {
    content: '“';
    left: -5rem;
    top: -2rem;
}
.Pricing-rating-wrapper {
    display: inline-block;
    width: 33%;
}
.Pricing-rating-chart {
    display: inline-block;
}
.Pricing-appstoreChat {
    width: auto;
    height: 100px;
    margin: 0 auto;
}
.Pricing-ratingview {
    display: inline-block;
    width: 26%;
    vertical-align: top;
    margin-top: 50px;
    margin-left: 10px;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #32a8ee;
}
.Pricing-viewAppStore {
    width: 18px;
    height: 18px;
}

.Pricing-td-special {
    padding: 6px 0;
    border-radius: 13.5px;
    background-color: #00d3af;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    width: 207px;
    line-height: normal;
    letter-spacing: 0.83px;
    text-align: center;
    color: #ffffff;
    margin: 0 auto;
    margin-top: -60px;
    margin-bottom: 35px;
    text-transform: uppercase;
}

.Pricing-td-buy-now-special {
    background-color: #32a8ee;
    color: #ffffff;
}

.Pricing-enterprise-plan-contact-us {
    padding: 12px 69px 12px 70px;
    border-radius: 33px;
    border: solid 2px #32a8ee;
    position: absolute;
    right: 5%;
    top: 50%;
    color: #32a8ee;
    font-weight: bold;
    background-color: transparent;
}

@media screen and (max-width: 650px) {

    .Pricing-enterprise-plan-contact-us {
        position: static;
        margin-top: 20px;
    }
    .Pricing-enterprise-plan-info {
        width: 100%;
    }
    .Pricing-free-btn {

        position: static;
        margin-top: 20px;
        max-width: 260px;
    }

    .Pricing-td-why {
        width: 100%;
        display: block;
        margin: 20px;
    }

    .Pricing-go-premium {
        width: 100%;
        max-width: none;
    }
}

.CheckoutForm-left {
    display: inline-block;
    max-width: 360px;
    min-width: 280px;
    padding: 30px 30px 100px 22px;
    background-color: #f8fafb;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #788995;
}

.Pricing-td-fast-price {
    color: darkred;
}
.Pricing-td-fast-price strong {
    font-weight: bold;
}

.Pricing_Main .CheckoutForm-shoppingcarthdr {
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1b2125;
    padding-bottom: 28px;
    border-bottom: 1px solid #e4eaee;
    margin-bottom: 50px;
}

.CheckoutForm-price {
    font-weight: bold;
    margin-bottom: 50px;
    margin-top: 9px;
}

.StripeElementWrapper {
    padding: 20px 10px;
    width: 100%;
    display: block;
}
.StripeElementWrapper h6 {
    margin-bottom: 20px;
    font-weight: bold;
}

.stripe-card {
    border: 1px solid #dddddd;
    padding: 20px;
}

.CheckoutForm-couponbox {
    padding: 13px 0 13px 0;
    border-top: 1px solid #e4eaee;
    margin-bottom: 22px;

}

.CheckoutForm-haveavoucher {
    border-bottom: 1px solid #e4eaee;
    padding-bottom: 16px;
    margin-bottom: 11px;
    background-image: url(../../assets/images/downarrow.svg);
    background-position: 95% 40%;
    background-repeat: no-repeat;
    background-size: 7px 10px;
    cursor: pointer;
}

.CheckoutForm-right {
    min-width: 500px;
    width: calc(100% - 380px);
    display: inline-block;
    vertical-align: top;
    margin-left: 20px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1b2125;
}

.CheckoutForm-buynowbtn {
    font-weight: bold;
    text-transform: uppercase;
    padding: 18px 41px;
    border-radius: 3px;
    background-color: #0077e2;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #ffffff;
    border: none;
    margin-top: 20px;
}

.CheckoutForm-subtotalhdr {
    display: inline-block;
    font-weight: 300;
    width: 80%;
}

.CheckoutForm-subtotalamt {
    display: inline-block;
    font-weight: bold;
    width: 20%;
}
.CheckoutForm-safemoneytransfer {

    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: #788995;
    margin: 9px 0 0;
}

.CheckoutForm-subtotal {
    padding-bottom: 28px;
    border-bottom: 1px solid #e4eaee;
    margin-bottom: 24px;
}

.CheckoutForm-totalhdr {
    display: inline-block;
    width: 80%;
}

.CheckoutForm-totalamt {
    display: inline-block;
    width: 20%;
    font-weight: bold;
}

.Pricing_Main .CheckoutForm h2 {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1b2125;
}

.CheckoutForm-right h2 {
    margin-bottom: 37px;
}

.CheckoutForm-paymentgroup {
    border: 1px solid #dde3e8;
    border-radius: 4px;
    padding: 30px;
    cursor: pointer;
}

.Pricing-facebookbanner {
    background-color: #F5FBFE;
    padding: 30px;
    color: #123048;
    font-weight: bold;
}
.CheckoutForm-paymenttype {
    font-weight: bold;
    display: inline-block;
    width: 40%;
}
.CheckoutForm-paymentcards {
    display: inline-block;
    width: 60%;
}

.CheckoutForm-recipientpersonalmsg {
    height: 150px;
}
.CheckoutForm {
    margin-bottom: 100px;
}

.CheckoutForm-buynowwrapper {
    text-align: right;
}

.CheckoutForm-producttitle {
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #788995;
}

.CheckoutForm-poweredbystripe {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: center;
    color: #788995;
}

@media screen and (max-width: 1000px) {
    .CheckoutForm-right {
        width: 100%;
        min-width: auto;
        display: block;
        max-width: none;
        margin: 0;
        order: 0;
        margin-bottom: 50px;
    }

    .CheckoutForm-left {
        min-width: auto;
        display: block;
        max-width: none;
        order: 1;
    }

    .CheckoutForm {
        display: flex;
        flex-flow: column;
    }
    .CheckoutForm-paymenttype {
        width: 100%;
    }

    .Pricing-td {
        width: 100%;
        display: block;
        margin: 0 auto 20px auto;
    }
}
@media screen and (max-width: 500px) {
    .Pricing-enterprise-plan {
        padding: 30px;
    }
    .Pricing_Main .Pricing-why-sumizeit-hdr {
        font-size: 30px;
    }
    .Pricing_Main .Pricing-are-you-a-business-hdr {
        font-size: 30px;
    }
    .Pricing_Main .Pricing-faq-hdr {
        font-size: 30px;
    }
    .Pricing-benefit {
        margin-right: 0;
    }
    .Pricing_Main .Pricing-premium-features-hdr {
        font-size: 30px;
    }
    .Pricing-appstoreChat {
        width: 100%;
        height: auto;
        margin: 0 auto;
    }
    .Pricing_Main .Pricing-go-premium {
        font-size: 30px;
    }
    .Pricing .AsFeaturedOn {
        width: 100%;
    }
}

@media screen and (max-width: 360px) {
    .CheckoutForm-left {
        width: 100%;
        display: block;
        max-width: none;
    }
}
