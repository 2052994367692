.Giftcard-hero {
    position: relative;
    width: 100%;
    height: 490px;
    background: #F5FBFE;
    mix-blend-mode: normal;
    text-align: left;
}
.Giftcard-img {
    position: absolute;
    height: 427px;
    max-width: 550px;
    left: 50%;
    width: 100%;
    top: 5%;
    background-image: url("../../assets/images/gift.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}
.Giftcard-herotitle {
    position: absolute;
    max-width: 537px;
    height: 136px;
    width: 84%;
    left: 8%;
    top: 5%;
    font-family: Helvetica Neue, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    /* or 113% */
    letter-spacing: -1px;
    color: #001A49;
}

.Giftcard-herosubtitle {
    position: absolute;
    max-width: 560px;
    width: 84%;
    height: 60px;
    left: 8%;
    top: 33%;

    font-family: Helvetica Neue, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    text-align: left;
    line-height: 30px;
    color: #4F596A;
}
.Giftcard-benefits {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    vertical-align: top;
    margin: 0 0 100px 0;
}
.Giftcard-benefit {
    text-align: center;
    width: 25%;
    margin-right: 50px;
}

.Giftcard-benefit h3 {
    margin-bottom: 20px;
}
.Giftcard-benefit img {
    width: 50px;
    height: auto;
    text-align: center;
    margin-bottom: 20px;
}

.Giftcard-inner {
    margin: 0 auto;
}

.Giftcard-moreinfo {
    margin: 40px auto 40px auto;
    max-width: 500px;
    line-height: 24px;
    padding: 0 30px;
}

.Giftcard-whatmakes {
    margin: 60px;
    text-align: center;
}

.Giftcard-plan {
    max-width: 400px;
    padding: 20px;
    color: #3a4649;
    font-size: 16px;
    margin: 0px auto 50px auto;
    border: 3px solid #32A8EE;
}

.Giftcard-plan h2 {
    font-size: 24px;
    color: #042330;
    margin-bottom: 20px;
}

.Giftcard-planprice {
    color: #042330;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
}

.Giftcard-planpricefineprint {
    font-size: 14px;
    color: #888888;
    margin-bottom: 10px;
}

.Giftcard-plan button {
    margin-top: 30px;
}

@media screen and (max-width: 1130px) {

    .Giftcard-img {
        width: 360px;
        height: 360px;
        left: 55%;
        top: 25%;
    }

}
@media screen and (max-width: 830px) {
    .Giftcard-img {
        width: 200px;
        height: 200px;
        left: 8%;
        top: 55%;
    }

    .Giftcard-benefits {
        display: block;
        width: 100%;
        margin: 0;
        padding: 10px;
    }

    .Giftcard-benefit {
        width: 100%;
        margin: 0 0 60px 0;
        padding: 0;
    }
}