.Admin {
    border-collapse: collapse;
    width: 100%;
    margin-top: 50px;
}

.Admin td, .Admin th {
    padding: 8px;
}

.Admin_Navigation {
    margin-bottom: 30px;
}

.AdminUser_ColHeader {
    padding: 20px;
    background-color: #eeeeee;
    font-weight: bold;
}

.AdminUser_Col {
    padding: 20px;
}

.Admin tr:nth-child(even){background-color: #f9f9fb;}

.Admin tr:hover {background-color: #ddd;}

.Admin textarea {
    width: 600px;
    height: 120px;
    border: 3px solid #cccccc;
    padding: 10px;
    background-position: bottom right;
    background-repeat: no-repeat;
}

.Admin th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f2f3f7;
    color: #72778d;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
}


.Admin_dropbtn {
    background-color: #fff;
    color: #607184;
    border: 1px solid #607184;
    padding: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.Admin_dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.Admin_dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.Admin_dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.Admin_dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.Admin_dropdown:hover .Admin_dropdown-content {
    display: block;
}

.AdminEdit input[type=text], select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.AdminEdit input[type=submit] {
    width: 400px;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px auto;
    text-align: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.AdminEdit input[type=submit]:hover {
    background-color: #45a049;
}

.AdminEdit > div {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
}
