/* The side navigation menu */
.sidebar {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    top: 0; /* Stay at the top */
    left: 0;
    z-index: 10000;
    background-color: #fff; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding: 0;
    margin: 0;
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

/* The navigation menu links */
.sidebar a {
    text-decoration: none;
    display: block;
    transition: 0.3s;
}

.sidebar-link {
    cursor: pointer;
}

/* When you mouse over the navigation links, change their color */
.sidebar a:hover {
}

/* Position and style the close button (top right corner) */
.sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}