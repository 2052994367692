.Home {
    max-width: 1200px;
    margin: 50px auto;
    padding: 0 30px;
}
.Home-top {
    display: block;
}

.Home_Visual_Prev {
    background-image: url("/images/prev-btn.svg");
    border: none;
    background-color: transparent;
    width: 50px;
    height: 50px;
}

.Visual_P {
    margin-bottom: 50px;
    margin-top: 10px;
}
.Home_Visual_Next {
    background-image: url("/images/next-btn.svg");
    border: none;
    background-color: transparent;
    width: 50px;
    height: 50px;
}

.Visual {
    max-width: 600px;
    margin: 20px auto 0 auto;
    text-align: center;
}

.Visual h2 {
    margin-bottom: 20px;
}

.Home_Visual_Back_To {
    margin-top: 30px;
}

.Home_Visual_Img {
    width: 500px;
    height: auto;
    max-width: 100%;
    margin: 30px auto;
}

.Home_Visual_Buttons button:first-of-type {
    margin-right: 20px;
}

.Home-toplink {
    vertical-align: top;
    display: inline-block;
    margin-right: 20px;
    background: #FFFFFF;
    box-shadow: 0px 14.3032px 25.7458px rgba(0, 0, 0, 0.05);
    border-radius: 4.57704px;
    padding: 6px 35px 25px 35px;
    padding-left: 120px;
    width: 320px;
    max-width: 400px;
    height: 115px;
    position: relative;
}

.Home-toplink a {
    position: absolute;
    left: 120px;
    bottom: 20px;
}

.Home-toplink button {
    border: none;
    border-radius: 8px;
    padding: 3px 10px;
    color: #FFFFFF;
    background: #00D3AF;
    display: inline-block;
    margin-top: 10px;
}

.Home-tendaychallenge {
    background-image: url(../../assets/images/take10day.png);
    background-position: 10px center;
    background-repeat: no-repeat;
    background-size: 90px auto;
}


.Home-randombook {
    background-image: url(../../assets/images/lightwecarry.jpg);
    background-position: 10px center;
    background-repeat: no-repeat;
    background-size: 90px auto;
}

.Home-tendaychallenge button {
    color: #FFFFFF;
    background: #000000;
}
.Home-infographics button {
    color: #FFFFFF;
    background: #32A8EE;
}

.Home-infographics {
    background-image: url(../../assets/images/books_not_found_infographics_body.png);
    background-position: 10px center;
    background-repeat: no-repeat;
    background-size: 90px auto;
}

.Home-title {
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: -1px;
    text-align: left;
    color: #001a49;
}
.Home-box {
    margin-top: 80px;
}
.Home-boxtop {
    margin-bottom: 30px;
}
.Home a {
    color: #4151e6;
}
.Home-fineprint {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #868686;
}
.Home-boxtop h2 {
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.29;
    letter-spacing: normal;
    text-align: left;
    color: #123048;
    float: left;
    width: 50%;
}


.Home-boxtopright {
    float: right;
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.29;
    letter-spacing: normal;
    text-align: right;
    color: #4151e6;
    vertical-align: middle;
    padding-top: 5px;
    width: 50%;
}

.Home-item {
    margin-right: 20px;
    margin-bottom: 20px;
    word-wrap: break-word;
    display: inline-block;
    width: 170px;
    vertical-align: top;
}

.Home-bookitem-wrapper {
    border-radius: 10px;
    border: none;
    width: 170px;
    background-position: center center;
    background-repeat: no-repeat;
    height: 170px;
    vertical-align: top;
    margin-bottom: 15px;
}

.Home-bookitem {
    background-color: transparent;
    border: none;
    width: 100%;
    background-size: 91px 141px;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    vertical-align: top;
}

.Home-pathitem {
    border: none;
    width: 175px;
    height: 175px;
    vertical-align: top;
    margin-bottom: 15px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.Home-itemtitle {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: -0.08px;
    text-align: left;
    color: #4151e6;
    word-wrap: break-word;
}

.Home-itemauthor {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #868686;
    margin-top: 5px;
}

.Home-courseitem {
    border-radius: 50%;
    width: 170px;
    height: 170px;
    background-color: transparent;
    border: none;
    margin-bottom: 15px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.Home .Home-currentlyviewing-title {
    margin-bottom: 30px;
}
.Home-nav button {
    background-color: transparent;
    border: none;
    width: 200px;
    text-align: left;
    border-bottom: 2px solid #ededed;
    padding-bottom: 10px;
    color: #888888;
    font-size: 18px;
}
.Home-pickUp {
    color: #82919b;
}
.Home-nav {
    margin-bottom: 20px;
}
.Home-books {
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 0;
    margin-top: 30px;
    justify-content: left;
}

.Home-nav .active {
    color: #123048;
    border-bottom: 2px solid #123048;
}

.Home-book {
    margin-right: 20px;
    margin-bottom: 30px;
    width: 170px;
    word-wrap: break-word;
}

.Home-bookimg {
    width: 170px;
    height: 255px;
    vertical-align: top;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    border: none;
}

.Home-loadmore {
    background-color: transparent;
    border: 2px solid #888888;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.08px;
    color: #4151e6;
    padding: 10px;
    margin: 0 auto;
    text-align: center;
    display: inline-block;
}

.Home-loadmore-wrapper {
    margin: 50px auto;
    text-align: center;
}

.Home-booktitle {
    margin: 15px 0 5px 0;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.08px;
    text-align: left;
    color: #4151e6;
}

.Home-bookauthor {
    margin: 5px 0 0 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #868686;
}

.Home-newtag {
    color: #ff445d;
    margin: 0px 0 5px 0;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
}

.Home-adv {
    border-radius: 10px;
    background-color: #f6f9ff;
    padding: 20px;
    margin: 0 0 50px 0;
}

.Home-adv p, .Home-adv h2 {
    margin-bottom: 10px;
}

.Home-course {
    margin-right: 20px;
    margin-bottom: 20px;
    width: 360px;
    min-width: 260px;
    height: 340px;
    border-radius: 10px;
    background-color: #f6f9ff;
}
.Home-nothinghere {
    text-align: center;
    margin: 20px auto;
}
.Home-courseimg {
    width: 100%;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.Home-coursetitle {
    margin: 22px 21px 35.5px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    height: 56px;
    letter-spacing: normal;
    text-align: left;
    color: #123048;
    display: block;
}

.Home-courseauthor {
    margin: 10px 21px 35.5px 21px;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #5a5a5a;
}

.Home-bookimg img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}

.Home-categoryname {
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    color: #123048;
    border-radius: 8px;
    border: solid 1px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    padding: 10px;
    margin: 0px 0px 8px 8px;
    text-align: left;
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.Home-categories {
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
    margin: 0;
    padding: 0;
}

.Home-categoryname a {
    line-height: 36px;
    color: #123048;
    font-weight: bold;
    text-align: left;
}

.Home-recommends {
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
}

.Home-recommend.Course {
    background-image: url("../../assets/images/course-icon.svg");
    background-size: 30px 30px;
    background-position: 12px center;
    background-repeat: no-repeat;
}

.Home-recommend.Path {
    background-image: url("../../assets/images/path-icon.svg");
    background-size: 30px 30px;
    background-position: 12px center;
    background-repeat: no-repeat;
}

.Home-pathstitle {
    background-image: url("../../assets/images/path-icon.svg");
    background-size: 24px 24px;
    background-position: 12px center;
    padding-left: 50px;
    background-repeat: no-repeat;
}

.Home-trendingcoursetitle {
    background-image: url("../../assets/images/course-icon.svg");
    background-size: 24px 24px;
    background-position: 12px center;
    padding-left: 50px;
    background-repeat: no-repeat;
}

.Home-bookstitle {
    background-image: url("../../assets/images/book-icon.svg");
    background-size: 24px 24px;
    background-position: 12px center;
    background-repeat: no-repeat;
    padding-left: 50px;
}

.Home-recommend.Book {
    background-image: url("../../assets/images/book-icon.svg");
    background-size: 30px 30px;
    background-position: 12px center;
    background-repeat: no-repeat;
}

.Home-minititle {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.29;
    letter-spacing: normal;
    text-align: left;
    color: #123048;
}

.Home-recommend {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 36px;
    letter-spacing: -0.08px;
    text-align: left;
    color: #4151e6;
    padding: 12px 15px 12px 75px;
    background-color: #f5fbfe;
    margin: 0;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 550px;
    min-height: 60px;
}

.Home-boxcategories .Home-boxtop h2 {
    width: 100%;
}

@media (max-width: 1000px) {
    .Home-toplink {
        margin-top: 28px;
    }

    .Home-tendaychallenge {
        margin-top: 0;
    }
}

@media (max-width: 400px) {
    .Home-boxtop h2 {
        float: none;
        width: 100%;
    }
    .Home-boxtopright {
        float: none;
        width: 100%;
        text-align: left;
    }
}