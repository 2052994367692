.Audio .audio-progress-bar-wrapper {
    width: calc(100% - 142px);
    height: 5px;
    border-radius: 4px;
    background-color: rgba(111, 119, 125, 0.6);
    vertical-align: middle;
    position: relative;
}
.Audio .audio-progress {
    height: 5px;
    background-color: #32a8ee;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 4px;
}

.Audio .audio-bubble {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #25a0ea;
    position: absolute;
    left: 0;
    top: -4.5px;
}

.Audio {
    width: 100%;
}
.Audio div {
    display: inline-block;
}

.Audio .current-time {
    width: 50px;
    vertical-align: middle;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.08px;
    text-align: left;
    color: #002f6e;
}
.Audio .duration {
    width: 50px;
    vertical-align: middle;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.08px;
    text-align: left;
    color: #002f6e;
}
.Audio .rate {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.08px;
    text-align: center;
    color: #002f6e;
    background-color: transparent;
    border: none;
}

.Audio audio {
    display: none;
}
.Audio .play-pause {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 42px;
    vertical-align: middle;
    border: none;
    background-color: transparent;
    height: 42px;
}
.Audio .icon-pause {
    background-image: url('/images/pause.png');
}

.Audio .icon-play {
    background-image: url('/images/play_course.png');
}