.Video h2,
.Video p,
.Video i,
.Video a {
  text-rendering: optimizeLegibility;
}

.Video .paywall {
  text-align: center;
  padding: 30px;
  max-width: 500px;
  margin: 10px auto 0 auto;
  background-color: #F6F9FF;
  font-size: 16px;
  line-height: 1.3;
}

.Video .lastFree {
  text-align: left;
  padding: 10px 20px 20px 20px;
  font-family: "Helvetica Neue", Arial, sans-serif;
  width: 100%;
  margin: 10px auto 0 auto;
  background-color: #F6F9FF;
  line-height: 1.3;
}

.Video .lastFree p {
  font-size: 16px;
}

.Video .lastFree h2 {
  margin: 0;
}
.Video .paywall h1 {
  font-family: cera-bold, 'Helvetica Neue', Arial, sans-serif;
  font-size: 34px;
  margin: 20px 0;
  font-weight: 400;
  text-align: center;
  line-height: 1.2;
}

.Video h2 {
  font-family: sans-serif;
  font-size: 26px;
  font-weight: 700;
  padding: 0;
  margin-top: 21px;
  text-align: left;
  line-height: 34.5px;
  color: rgba(0, 0, 0, 0.84);
  letter-spacing: -0.45px;
}
