.TendayChallenge {
    max-width: 600px;
    margin: 30px auto;
}

.TendayChallenge_Header {
    height: 150px;
    background-image: url(../../../assets/images/tendaychallengewoman.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.TendayChallenge_Checkbox {
    margin: 10px 0;
}

.TendayChallenge_Body {
    padding: 30px;
}

.TendayChallenge_Goals {
    margin: 30px 0;
    padding: 30px;
}

.TenDayChallenge_SaveResults {
    text-align: center;
    margin: 0 auto;
}

.TendayChallenge_Goals ul {
    list-style-type: disc;
}

.TendayChallenge_Body p, .TendayChallenge_Body h1 {
    margin-bottom: 20px;
}

.TendayChallenge_Input {
    padding: 0px 0 20px 0;
    margin-bottom: 10px;
}

.TendayChallenge_Input input {
    padding: 10px;
    width: 80%;
}

.TendayChallenge h1 {
    margin-top: 30px;
}

.TendayChallenge h3 {
    margin-bottom: 20px;
}

.TendayChallenge button {
    text-align: center;
    margin: 20px auto;
}

.TendayChallenge_Button {
    text-align: center;
    margin: 0 auto;
}

.TendayChallenge_Book {
    text-align: center;
    padding: 20px;
}

.TendayChallenge_DayNo {
    padding: 10px;
    margin-bottom: 20px;
}

.TendayChallenge_BookImgWrapper {
    height: 240px;
}

.TendayChallenge_BookImg {
    width: 150px;
    height: auto;
    text-align: center;
    margin: 0 auto;
}

.TendayChallenge_CheckboxContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.TendayChallenge_CheckboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.TendayChallenge_Checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.TendayChallenge_CheckboxContainer:hover input ~ .TendayChallenge_Checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.TendayChallenge_CheckboxContainer input:checked ~ .TendayChallenge_Checkmark {
    background-color: #2196F3;
}

.TendayChallenge_Header_Share {
    padding: 30px;
    padding-left: 15px;
}

.TendayChallenge_Header_Share a {
    margin-right: 10px;
}
/* Create the checkmark/indicator (hidden when not checked) */
.TendayChallenge_Checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.TendayChallenge_CheckboxContainer input:checked ~ .TendayChallenge_Checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.TendayChallenge_CheckboxContainer .TendayChallenge_Checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media screen and (max-width: 800px) {
    .TendayChallenge_Body {
        padding: 20px;
    }
}