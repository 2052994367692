.Loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #32A8EE; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin: 0  auto;
    text-align: center;
    margin-top: 50px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* General container styles */
.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    overflow: hidden;
    position: relative;
}

/* Bubble styles */
.bubble {
    width: 20px;
    height: 20px;
    margin: 0 10px;
    background-color: white;
    border: 4px solid #FCCB00;
    border-radius: 50%;
    animation: bubble-move 1.5s ease-in-out infinite;
}

.bubble:nth-child(2) {
    animation-delay: 0.3s;
}

.bubble:nth-child(3) {
    animation-delay: 0.6s;
}

/* Bubble animation */
@keyframes bubble-move {
    0%, 100% {
        transform: translateY(0);
        opacity: 0.8;
    }
    50% {
        transform: translateY(-20px);
        opacity: 1;
    }
}
