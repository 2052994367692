.Invitations-hero {
    position: relative;
    width: 100%;
    height: 490px;
    background: #F5FBFE;
    mix-blend-mode: normal;
    text-align: left;
}
.Invitations-img {
    position: absolute;
    height: 400px;
    max-width: 50%;
    left: 50%;
    width: 100%;
    top: 15%;
    background-image: url("../../../assets/images/affiliate.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}
.Invitations-herotitle {
    position: absolute;
    max-width: 537px;
    height: 136px;
    width: 84%;
    left: 8%;
    top: 5%;
    font-family: Helvetica Neue, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    /* or 113% */
    letter-spacing: -1px;
    color: #001A49;
}

.Invitations-inner {
    margin: 0 auto;
    padding: 50px 100px;
}

.Invitations-herosubtitle {
    position: absolute;
    max-width: 560px;
    width: 84%;
    height: 60px;
    left: 8%;
    top: 43%;

    font-family: Helvetica Neue, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    text-align: left;
    line-height: 30px;
    color: #4F596A;
}
.Invitations-benefits {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    vertical-align: top;
    margin: 0 0 50px 0;
}
.Invitations-benefit {
    text-align: center;
    width: 25%;
    margin-right: 50px;
}

.Invitations-benefit h3 {
    margin-bottom: 20px;
}
.Invitations-benefit img {
    width: 50px;
    height: auto;
    text-align: center;
    margin-bottom: 20px;
}

.Invitations-whatmakes {
    margin: 60px;
    text-align: center;
}
.Invitations-ready {
    max-width: 400px;
    padding: 20px;
    color: #3a4649;
    font-size: 16px;
    margin: 0px auto 50px auto;
    border: 3px solid #32A8EE;
}

.Invitations-ready h2 {
    font-size: 24px;
    color: #042330;
    margin-bottom: 20px;
}

.Invitations-ready button {
    margin-top: 30px;
}

.InvitationHero {
     background-image: url("../../../assets/images/invitationhero.jpg");
     background-position: right center;
     background-repeat: no-repeat;
     background-size: auto 100%;
     position: relative;
     max-width: 930px;
     height: 290px;
     margin: 0 auto;
     background-color: #dee4e4;
 }


.InvitationHero-3 .hero-text {
    text-align: left;
}

.InvitationHeader {
    padding: 10px;
    line-height: 1.5;
    border: 1px solid #dddddd;
}

.InvitationHeader .small {
    color: #888888;
}

.InvitationHeader .big {
    font-weight: bold;
    font-size: 20px;
}

.InvitationHero .text {
    position: absolute;
    top: 10%;
    left: 5%;
    width: 40%;
    padding: 20px;
}

.InvitationHero .hero-text h2 {
    margin: 0px auto 30px auto;
}

.InvitationCode {
    margin: 30px;
    border: 2px solid #1a92c2;
    padding: 20px;
}
.InvitationCode input {
    border: 1px solid black;
    padding: 10px;
    width: 80%;
}
.SocialButtons {
    text-align: center;
    margin: 0 auto;
}

@media only screen and (max-width: 800px) {
    .InvitationHero .hero-text {
        text-align: center;
        position: static;
        width: 80%;
        padding-top: 20px;
        margin: 0 auto;
    }

    .InvitationHero .text {
        background-color:rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 10%;
        left: 0%;
        width: 100%;
        padding: 20px;
    }
}

.InvitationHeader {
    padding: 10px 30px;
    margin: 20px 0 40px 0;
}

.InvitationHeader .left {
    width: 50%;
    float: left;
    font-weight: bold;
}

.InvitationHeader .right {
    font-weight: bold;
    float: right;
}

.Invitations-whyjoinus {
    text-align: center;
    margin: 70px auto;
}
@media screen and (max-width: 830px) {
    .Invitations-img {
        width: 200px;
        height: 200px;
        left: 8%;
        top: 55%;
    }

    .Invitations-inner {
        margin: 0 auto;
        padding: 30px;
    }

    .Invitations-benefits {
        display: block;
        width: 100%;
        margin: 0;
        padding: 10px;
    }

    .Invitations-benefit {
        width: 100%;
        margin: 0 0 60px 0;
        padding: 0;
    }
}