.QuizTrueBtn {
    background-color: #4290f5;
    color: white;
    border-radius: 8px;
    padding: 20px;
    border: none;
    margin-right: 10px;
    z-index: 1000;
}
.QuizBtns {
    margin: 20px auto;
    z-index: 1000;
}
.QuizFalseBtn {
    border-radius: 8px;
    background-color: white;
    color: black;
    padding: 20px;
    border: 1px solid #000000;
    margin-right: 10px;
    z-index: 1000;
}
.QuizBody h1 {
    margin-bottom: 20px;
}
.QuizDoneBtn {
    background-color: #4290f5;
    color: white;
    padding: 20px;
    border: none;
    margin: 20px auto;
    border-radius: 8px;
}
.QuizBody {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}
.QuizImg {
    width: 200px;
    height: auto;
    margin: 0 auto 30px auto;
}
.QuizImg img {
    width: 100%;
    height: auto;
}

.QuizAnswerQues {
    margin-bottom: 20px;
}

.QuizIntelligenceType {
    font-size: 20px;
    margin: 20px;
}

.QuizBody {
    margin: 20px auto;
}
.QuizBodyTitle {
    margin: 30px auto 30px auto;
}

.QuizBody p {
    margin: 20px auto;
}

@media only screen and (min-width: 500px) {
    .QuizBody {
        width: 95%;
    }
}