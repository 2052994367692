.GetStarted_Goals {
    max-width: 500px;
    margin: 0 auto;
}

.GetStarted_SocialProof {
    max-width: 500px;
    margin: 20px auto;
    text-align: center;
}

.GetStarted_bookitem_wrapper {
    border-radius: 10px;
    border: none;
    width: 250px;
    background-position: center center;
    background-repeat: no-repeat;
    height: 250px;
    vertical-align: top;
    margin-bottom: 15px;
}
.GetStarted_Thumbs {
    margin: 20px auto;
    text-align: center;
}
.Getstarted_BookImg {
    width: 200px;
    height: auto;
    margin: 20px auto;
}
.GetStarted_Item {
    margin-right: 20px;
    margin-bottom: 20px;
    word-wrap: break-word;
    display: inline-block;
    width: 170px;
    vertical-align: top;
}

.GetStarted_Progress {
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
}
.GetStarted_ThumbsDown:last-of-type {
    margin-right: 0;
}
.GetStarted_Personalizing {
    text-align: center;
}
.GetStarted_Personalizing h1 {
    margin: 20px auto 40px auto;
    text-align: center;
}
.GetStarted_ThumbsDown {
    background-color: #eeeeee;
    padding: 20px;
    border: none;
    margin-right: 10px;
    border-radius: 8px;
}
.GetStarted_Books {
    margin: 20px auto;
    text-align: center;
}

.GetStarted_Book {
    margin: 20px auto;
}
.GetStarted_Home_bookitem {
    background-color: transparent;
    border: none;
    width: 100%;
    background-size: 150px auto;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    vertical-align: top;
}

.GetStarted_SocialProof img {
    width: 150px;
    height: auto;
    margin: 20px auto;
}

.GetStarted_Quote {
    margin: 20px auto;
    padding: 20px;
    background-color: #ecf9ff;
    border-radius: 8px;
    font-size: 24px;
}
.GetStarted_Continue {
    background-color: rgb(44, 224, 128);
    font-weight: bold;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    border: none;
    width: 100%;
    margin: 20px auto;
}

.GetStarted_Quote img {
    margin: 50px auto 20px auto;
}
.GetStarted_Goals  h1 {
    margin: 0 auto 20px auto;
    text-align: center;
}
.GetStarted_GoalsImg {
    text-align: center;
}
.GetStarted_GoalsImg img {
    width: 250px;
    height: auto;
    margin: 20px auto;
    text-align: center;
}


.GetStarted_MentionedIn {
    color: #888888;
}

.GetStarted_Goals  p {
    margin: 20px auto 30px auto;
    text-align: center;
}

.GetStarted_Goals  h2 {
    margin: 20px auto;
}

.GetStarted_Goals button {
    margin: 20px auto;
    text-align: center;
}

.GetStarted_Goals_Continue {
    text-align: center;
    margin: 20px auto;
}