@import url('https://fonts.googleapis.com/css?family=Lora|Playfair+Display:700i,900');

.Reader h2,
.Reader p,
.Reader i,
.Reader a {
    text-rendering: optimizeLegibility;
}

.Reader h1 {
    font-family: "Playfair Display", serif;
    font-size: 40px;
    text-align: left;
    margin-bottom: 12px;
    line-height: 1.3;
    color: rgba(0, 0, 0, 0.84);
}

.ReaderLink {
    text-decoration: underline;
    color: #F6F9FF;
    text-align: center;
}

.Reader .paywall {
    text-align: center;
    padding: 30px;
    max-width: 500px;
    margin: 10px auto 0 auto;
    background-color: #F6F9FF;
    font-size: 16px;
    line-height: 1.3;
}

.Reader .lastFree {
    text-align: left;
    padding: 10px 20px 20px 20px;
    font-family: "Lato", "Helvetica Neue", Arial, sans-serif;
    width: 100%;
    margin: 10px auto 0 auto;
    background-color: #F6F9FF;
    line-height: 1.3;
}

.Reader .lastFree p {
    font-size: 16px;
}

.Reader .lastFree h2 {
    margin: 0;
}
.Reader .paywall h1 {

    font-family: cera-bold, 'Helvetica Neue', Arial, sans-serif;
    font-size: 34px;
    margin: 20px 0;
    font-weight: 400;
    text-align: center;
    line-height: 1.2;
}

.Reader ul {
    list-style-type: initial;
}

.Reader  li {
    margin-top: 21px;
    font-family: "Lora", sans-serif;
    font-size: 20px;
    letter-spacing: -0.03px;
    line-height: 1.58;
    color: rgba(0, 0, 0, 0.84);
    margin-left: 20px;
}

.Reader h2 {
    font-family: "Lato", sans-serif;
    font-size: 26px;
    font-weight: 700;
    padding: 0;
    margin-top: 21px;
    text-align: left;
    line-height: 34.5px;
    color: rgba(0, 0, 0, 0.84);
    letter-spacing: -0.45px;
}

.Reader h3 {
    font-family: "Lato", sans-serif;
    font-size: 22px;
    font-weight: 700;
    padding: 0;
    margin-top: 21px;
    text-align: left;
    line-height: 34.5px;
    letter-spacing: -0.45px;
    color: rgba(0, 0, 0, 0.84);
}


.Reader p, .Reader i, .Reader a {
    margin-top: 21px;
    font-family: "Lora", sans-serif;
    font-size: 20px;
    letter-spacing: -0.03px;
    line-height: 1.57;
    color: rgba(0, 0, 0, 0.84);
}

.Reader .container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto 166px 740px 166px auto;
    grid-template-columns: auto 166px 740px 166px auto;
    -ms-grid-rows: 0px auto 150px;
    grid-template-rows: 0px auto 0px;
    grid-template-areas:
            ". img img img ."
            ". . article . ."
            ". . footer . .";
}
.Reader  .article {
    -ms-grid-row: 2;
    -ms-grid-column: 3;
    grid-area: article;
    margin: 40px 10px 40px 30px;
}

.Reader .paywall h2 {
    text-align: center;
}

@media screen and (max-width: 1072px) {
    .Reader .container {
        -ms-grid-columns: auto 740px auto;
        grid-template-columns: auto 740px auto;
        grid-template-areas:
                ". img ."
                ". article ."
                ". footer  .";
    }
    .Reader .article {
        -ms-grid-row: 2;
        -ms-grid-column: 2;
    }
}

@media screen and (max-width: 740px) {
    .Reader h1 {
        font-size: 30px;
        line-height: 40px;
    }

    .Reader p, .Reader i, .Reader a {
        font-size: 18px;
        line-height: 28px;
    }

    .Reader  li {
        font-size: 18px;
        line-height: 28px;
    }

    .Reader .container {
        -ms-grid-rows: auto auto 150px;
        grid-template-rows: auto auto 0px;
        -ms-grid-columns: auto;
        grid-template-columns: auto;
        grid-template-areas:
                "img"
                "article"
                "footer";
    }

    .Reader .article {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
    }
}
